import 'normalize.css'
import './src/index.css'

import React from 'react'
import DietIDApp from './src/App'
import Bugsnag from './src/components/Bugsnag'

const el = document.getElementById('root')

//console.log('%c Version 2021.02.11.0 ', 'background: rgb(232, 239, 239); color: rgb(28, 98, 98)')

const App = () => {
  const isTangelo = window.location.href.includes('tangelo')
  const isStaging = window.location.href.includes('staging.')
  const subdomain = (window.location.hostname.split('.')[0] || '').trim()
  document.addEventListener('DOMContentLoaded', () => {
    if (isTangelo && subdomain) {
      const cssURL = `https://${isStaging ? 'app.beta' : 'app'}.jointangelo.com/style/${subdomain}.css`
      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.type = 'text/css'
      link.href = cssURL
      document.head.appendChild(link)
    }
  })

  return (
    <Bugsnag>
      <DietIDApp partnerCode={el.getAttribute('partnerCode')} partnerUserId={el.getAttribute('partnerUserId')} />
    </Bugsnag>
  )
}

export default App
