import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Tag } from '@blueprintjs/core'
import * as constants from 'app/lib/constants'
import colors from 'app/lib/colors'
import t from 'app/lib/i18n'
import Icon from '../../../components/Icon'

const activeCircleSize = 22
const circleSize = 18
const innerCircleSize = 8
const lineHeight = 2
const lineOffset = Math.floor(activeCircleSize / 2)

const StyledTag = styled(Tag)`
  margin: 0 auto;
  display: block;
  width: 50px;
  text-align: center;
`

const Wrap = styled.div`
  .noprogressbar-view & {
    display: none;
  }
  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    margin-top: ${(props) => (props.nomenu ? '0' : '40px')};
    margin-bottom: 40px;
  }
  .progress-tabs {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 20px 13px 0;
  }

  .noprogressbartop-view & .progress-tabs{
    display: none;
  }
  
  .progress-tab {
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 13px 0;
    background: ${(props) => colors.hexToRgb(props.theme.primary, 0.1)};
    border-left: 1px solid #fff;
    color: #999;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    color: ${(props) => props.theme.primary};
    text-transform: uppercase;
    &.madeeasymeals {
      @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
        font-size: 9px;
      }
    }
    :first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    :last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    :before {
      position: absolute;
      top: calc(50% - 10px);
      right: -6px;
      width: 20px;
      height: 20px;
      display: block;
      content: '';
      z-index: 1;
      transform: rotate(45deg);
      pointer-events: none;
      background-color: ${(props) => colors.hexToRgb(props.theme.primary, 0.8, '#fff')};
      opacity: 0;
    }

    &.complete,
    &.active {
      border-left-color: ${(props) => colors.hexToRgb(props.theme.primary, 0.1, '#fff')};
    }

    &.complete {
      background: ${(props) => props.theme.primary};
      color: #fff;

      .icon {
        display: block;
      }
    }

    &.active {
      background: ${(props) => colors.hexToRgb(props.theme.primary, 0.8, '#fff')};
      color: #fff;

      :not(:last-child):before {
        opacity: 1;
      }
    }

    .icon {
      font-size: 32px;
      height: 10px;
      margin: 0px 5px 0 0;
      display: none;
      color: #fff;
      @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
        margin: 0px -10px 0 0;
      }
    }
    .icon * {
      font-size: 32px;
    }
  }

  .progress-bar {
    position: relative;
    height: 6px;
    margin: 8px 13px;
    border-radius: 3px;
    background: ${(props) => colors.hexToRgb(props.theme.primary, 0.1)};

    .bar {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background: ${(props) => props.theme.primary};
      border-radius: 5px;
      transition: width 500ms ease-in-out;
    }
  }

  .progress-steps {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }

  .progress-step {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    align-items: center;

    .progress-circle {
      position: relative;
      z-index: 2;
      height: ${innerCircleSize}px;
      width: ${innerCircleSize}px;
      border-radius: ${circleSize}px;
      background-color: ${(props) => colors.hexToRgb(props.theme.primary, 0.16)};
      border: 1px ${(props) => props.theme.primary} solid;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translate(0, -${(activeCircleSize - innerCircleSize) / 2}px);
    }

    &.complete .progress-circle {
      height: ${circleSize}px;
      width: ${circleSize}px;
      border-radius: ${circleSize}px;
      background-color: ${(props) => props.theme.primary};
      transform: translate(0, -${(activeCircleSize - circleSize) / 2}px);

      :before {
        content: ' ';
        height: ${innerCircleSize}px;
        width: ${innerCircleSize}px;
        border-radius: ${innerCircleSize}px;
        background-color: ${colors.white};
      }
    }

    :before {
      content: ' ';
      z-index: 0;
      position: absolute;
      bottom: ${lineOffset}px;
      left: 0;
      right: 0;
      height: ${lineHeight}px;
      width: calc(50% - ${innerCircleSize / 2}px);
      background-color: ${(props) => colors.hexToRgb(props.theme.primary, 0.36)};
    }

    :after {
      content: ' ';
      z-index: 0;
      position: absolute;
      bottom: ${lineOffset}px;
      left: calc(50% + ${innerCircleSize / 2}px);
      right: 0;
      height: ${lineHeight}px;
      width: calc(50% - ${innerCircleSize / 2}px);
      background-color: ${(props) => colors.hexToRgb(props.theme.primary, 0.36)};
    }

    &:first-of-type {
      :before {
        width: 0%;
      }
    }

    &:first-of-type.next {
      :before {
        width: 0%;
      }
    }

    &:last-of-type {
      :after {
        width: 0%;
      }
    }
    &:last-of-type.next {
      :after {
        width: 0%;
      }
    }

    &.complete {
      :after {
        background-color: ${(props) => props.theme.primary};
      }
      :before {
        background-color: ${(props) => props.theme.primary};
      }
    }

    /* The "next" one should have color before and gray after 50/50 */
    &.next {
      .progress-active-line {
        visibility: visible;
        height: 13px;
        width: 1px;
        background-color: ${(props) => props.theme.primary};
      }

      .progress-circle {
        transform: translate(0, 0px);
        width: ${activeCircleSize}px;
        height: ${activeCircleSize}px;
        border-radius: ${activeCircleSize}px;
        border: 1px ${(props) => props.theme.primary} solid;
        background-color: ${(props) => colors.hexToRgb(props.theme.primary, 0.36)};

        :before {
          content: ' ';
          background-color: ${(props) => props.theme.primary};
          width: ${innerCircleSize}px;
          height: ${innerCircleSize}px;
          border-radius: ${circleSize}px;
          margin-left: 0px;
        }
      }

      :before {
        content: ' ';
        z-index: 0;
        position: absolute;
        bottom: ${Math.floor(activeCircleSize / 2 + lineHeight / 2)}px;
        bottom: ${lineOffset}px;
        left: 0%;
        height: ${lineHeight}px;
        width: calc(50% - ${activeCircleSize / 2}px);
        background-color: ${(props) => props.theme.primary};
      }

      :after {
        content: ' ';
        z-index: 0;
        position: absolute;
        bottom: ${lineOffset}px;
        left: calc(50% + ${activeCircleSize / 2}px);
        right: 0;
        height: ${lineHeight}px;
        width: calc(50% - ${activeCircleSize / 2}px);
        background-color: ${(props) => colors.hexToRgb(props.theme.primary, 0.36)};
      }
    }
  }

  .progress-active-line {
    display: none;
    visibility: hidden;
  }

  .powered-by {
    font-size: 12px;
    font-weight: bold;
    margin-top: 20px;

    display: ${(props) => (props.neverShowLogo ? 'none!important' : 'block')};
    @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
      display: ${(props) => (props.alwaysShowLogo ? 'block' : 'none')};
    }
  }
`

const Logo = styled.img`
  min-height: 42px;
  max-height: 100px;
  max-width: 300px;
  margin: 10px auto 0;
  margin-bottom: -10px;

  display: ${(props) => (props.neverShowLogo ? 'none!important' : 'block')};
  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    display: ${(props) => (props.alwaysShowLogo ? 'block' : 'none')};
  }
`

const isTabComplete = (tab, progress) =>
  progress
    .filter((progress) => tab.steps.includes(progress.step))
    .map((progress) => progress.complete)
    .every((c) => !!c)

const isTabActive = (tabs, tab, progress) =>
  !isTabComplete(tab, progress) && (tabs.indexOf(tab) === 0 || isTabComplete(tabs[tabs.indexOf(tab) - 1], progress))

const ProgressTabs = ({ tabs, progress, partner }) => {
  const getTabClass = (tab) =>
    _.compact([
      isTabComplete(tab, progress) ? 'complete' : null,
      isTabActive(tabs, tab, progress) ? 'active' : null
    ]).join(' ')
  const getPartnerClass = () => partner.code ?? ''
  return (
    <div className="progress-tabs">
      {tabs.map((tab, idx) => (
        <div key={idx} className={`progress-tab ${getTabClass(tab)} ${getPartnerClass()}`}>
          <Icon className={'icon'} name={'check'} /> {t(tab.label)}
        </div>
      ))}
    </div>
  )
}

const Progress = ({ alwaysShowLogo, neverShowLogo, nomenu, partner, workflow }) => {
  const { step, stepProgress } = workflow

  if (!step) {
    return null
  }

  let tabs

  if (partner.code === 'nbty') {
    tabs = [
      {
        id: 'your-diet',
        label: 'Your Diet',
        steps: ['diet_screener', 'diet_id']
      },
      {
        id: 'about-you',
        label: 'About You',
        steps: ['custom_questions', 'diet_restriction_choices', 'user_info']
      },
      {
        id: 'your-results',
        label: 'Your Results',
        steps: ['id_results']
      }
    ]
  } else {
    tabs = [
      {
        id: 'screeners',
        label: partner.code === 'madeeasymeals' ? 'Take the Quiz' : 'Screeners',
        steps: ['custom_user_consent', 'dob_check', 'simple_screener', 'diet_screener']
      },
      {
        id: 'id',
        label: partner.code === 'madeeasymeals' ? 'Get Your Score' : 'ID',
        steps: ['diet_id', 'custom_questions', 'diet_restriction_choices', 'user_info', 'id_results']
      },
      {
        id: 'ideal',
        label: partner.code === 'madeeasymeals' ? 'Start Made Easy Diet' : partner.code?.includes('tangelo') || partner.code?.includes('tangelorx')  ? 'Health Conditions' : 'Goals',
        steps: ['ideal_goals', 'diet_ideal', 'ideal_results', 'id_to_ideal_results'],
        hidden: partner.disable_ideal
      },
      {
        id: 'navigation',
        label: 'Challenges',
        steps: ['navigation'],
        hidden: !partner.navigation_active
      }
    ].filter((tab) => !tab.hidden)
  }

  const percentComplete = stepProgress.totalSteps > 0 ? stepProgress.completedSteps / stepProgress.totalSteps : 0

  return (
    <Wrap alwaysShowLogo={!!alwaysShowLogo} neverShowLogo={!!neverShowLogo} nomenu={nomenu}>
      <Logo alwaysShowLogo={!!alwaysShowLogo} neverShowLogo={!!neverShowLogo} src={partner.logo_small_url} />
      {partner.show_powered_by_diet_id && <div className="powered-by">{t('Powered by Diet ID')}</div>}
      <ProgressTabs tabs={tabs} progress={step.progress} partner={partner} />
      {tabs.find((tab) => isTabActive(tabs, tab, step.progress)) && (
        <div className="progress-bar">
          <div className="bar" style={{ width: percentComplete * 100 + '%' }}></div>
        </div>
      )}
      {['development', 'staging'].includes(constants.ENVIRONMENT) && (
        <StyledTag minimal>
          {stepProgress.completedSteps} / {stepProgress.totalSteps}
        </StyledTag>
      )}
    </Wrap>
  )
}

export default connect((state, ownProps) => ({
  partner: ownProps.partner || (state.general.partnerId ? state.data.partner[state.general.partnerId] : null),
  workflow: ownProps.workflow || state.workflow
}))(Progress)
