import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'
import { DateInput } from '@blueprintjs/datetime'

import { Header as _Header } from 'app/components/Core'
import BMI from 'app/components/BMI'
import Icon from 'app/components/Icon'
import ConferMedUserInfo from 'app/custom/confermed/ConferMedUserInfo'
import NaturesBountyUserInfo from 'app/custom/naturesbounty/NaturesBountyUserInfo'
import * as constants from 'app/lib/constants'
import colors from 'app/lib/colors'
import t from 'app/lib/i18n'
import { getWorkflowUserInfo } from 'app/lib/integration'
import { getUserInfoFields, conditionalUserInfoFields } from './logic'
import NextButton from './NextButton'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    max-width: 550px;
    margin: 0 auto;
  }

  .bp3-popover-target {
    width: 100%;
  }

  .bp3-input {
    font-size: 32px;
    border: 1px #dfe3e3 solid;
    padding: 5px 17px;
    color: #575959;
    line-height: 1.15;
    outline: none;
    height: 100%;
    box-shadow: none;
    border-radius: 0;

    :focus,
    &.bp3-active {
      box-shadow: none;
    }
  }
`

const Header = styled(_Header)`
  font-size: 18px;
  margin: 0px 12px 15px;
  padding: 0;
`

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
`

const FieldOptions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FieldOptionsRows = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const FieldContent = styled.div`
  box-sizing: border-box;
  width: 100%;
`

const FieldLabel = styled.div`
  font-weight: bold;
  color: ${colors.text};
  padding: 12px 5px 12px 0;
  box-sizing: border-box;
  min-width: 120px;
  width: 120px;
  max-width: 120px;

  @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
    min-width: 80px;
    width: 80px;
    max-width: 80px;
  }
`

const FieldError = styled.div`
  color: ${colors.white};
  background-color: ${colors.red};
  margin: 12px 0;
  padding: 12px;
  border-radius: 4px;
`

const TappableOption = styled.div`
  cursor: pointer;
  border: 1px ${colors.lightGray3} solid;
  padding: 12px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 5px;
  color: ${colors.text};
  font-size: 18px;

  &.selected {
    background-color: ${(props) => props.theme.primary};
    border-color: ${(props) => props.theme.primary};
    color: white;
    z-index: 1;

    .description {
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .row {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
  }

  .description {
    font-size: 15px;
    color: ${lighten(0.2, colors.text)};
  }

  .icon-wrap {
    padding-right: 8px;

    > * {
      font-size: inherit;
    }
  }
`

const Field = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  padding: 6px 12px;

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    :not(.vertical) {
      ${FieldOptions} {
        flex-direction: row;
      }
    }

    ${TappableOption} {
      margin: 0;

      :not(.selected):hover {
        color: ${(props) => props.theme.primary};
      }
    }

    :not(.vertical) ${TappableOption} {
      margin-left: -1px;

      .row {
        justify-content: center;
      }
    }

    &.vertical {
      ${TappableOption} {
        margin-top: -1px;
      }
    }
  }
`

const NumberField = styled.div`
  border: 1px ${colors.lightGray3} solid;
  padding: 0 12px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 5px;
  color: ${colors.lightGray};
  font-size: 18px;

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    margin-bottom: 0;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon {
    color: ${(props) => props.theme.primary};

    &.invalid {
      opacity: 0;
    }
  }

  .input {
    font-size: 32px;
    padding: 5px;
    border: 0;
    width: 100px;
    background-color: transparent;
    color: ${colors.darkGray};
    outline: none;

    &.smaller {
      width: 50px;
    }

    &::placeholder {
      color: ${colors.lightGray};
      font-size: 24px;
    }
  }

  .label {
    font-size: 18px;
    flex: 1;
  }
`

const DisplayField = styled.div`
  border: 1px solid ${colors.lightGray3};
  padding: 10px;
`

const TextField = styled.div`
  border: 1px solid ${colors.lightGray3};

  input {
    font-size: 32px;
    padding: 5px 12px;
    border: 0;
    width: 100%;
    background-color: transparent;
    color: #575959;
    outline: none;
  }
`

const getIdDiet = (responses) =>
  (responses.diet_id || []).length > 1 && responses.diet_id[responses.diet_id.length - 1] === true
    ? responses.diet_id[responses.diet_id.length - 2]
    : null

const UserInfo = (props) => {
  const {
    partner,
    errors,
    step: { fields = [], id_diet_type: idDietType },
    onProgressChange
  } = props

  const nbty = partner.code === 'nbty'
  const confermed = partner.code === 'confermed'

  const partnerValues = nbty ? { weight_trend: 'constant' } : getWorkflowUserInfo()

  const prevInches =
    'height_inches' in partnerValues
      ? partnerValues.height_inches
      : (fields.find((field) => field.key === 'height_inches' && field.value >= 0) || {}).value

  const [showConferMed, setShowConferMed] = useState(false)
  const [showNaturesBounty, setShowNaturesBounty] = useState(false)
  const [values, setValues] = useState({})
  const [localErrors, setLocalErrors] = useState([])
  const [feet, setFeet] = useState(prevInches ? Math.floor(prevInches / 12) : null)
  const [inches, setInches] = useState(prevInches ? prevInches % 12 : null)

  const hasFeet = feet !== null && !isNaN(feet)
  const hasInches = inches !== null && !isNaN(inches)

  const setValue = (field, value) => setValues((values) => ({ ...values, [field]: value }))

  const formFields = getUserInfoFields(partner)

  const formIsPrefilled = formFields.every((field) => field.id in partnerValues)

  const submitValues = (values) => props.onSubmit(_.omit(values, hiddenConditionalFieldIds))

  useEffect(() => {
    const backendValues = fields.reduce((values, { key, value }) => ({ ...values, [key]: value }), {})
    const combinedValues = { ...backendValues, ...partnerValues }
    setValues(combinedValues)

    if (formIsPrefilled) {
      submitValues(combinedValues)
    }
  }, [fields])

  useEffect(() => {
    onProgressChange('UserInfo', 0)
  }, [])

  const renderTappableOption = (field, iconName, selected, option, styles = {}) => (
    <TappableOption
      key={option.value}
      className={selected ? 'selected' : null}
      onClick={() => setValue(field.id, option.value)}
      style={styles}
    >
      <div className="row">
        {option.icon && (
          <div className="icon-wrap">
            <Icon name={option.icon} />
          </div>
        )}
        <div className="label">{option.label}</div>
      </div>
      {option.description && (
        <div className="row">
          <div className="description">{option.description}</div>
        </div>
      )}
    </TappableOption>
  )

  const renderTappableFieldRows = (field, iconFn, optionsRowFormat = null, renderOption) => {
    return (
      <FieldOptionsRows>
        {optionsRowFormat.map((row, idx) => {
          return (
            <FieldOptions key={field.id} style={idx > 0 ? { marginTop: -1 } : null}>
              {row.map((oval) =>
                renderOption(
                  field.options.find((o) => o.value === oval),
                  { display: 'flex', alignItems: 'center', alignSelf: 'stretch', textAlign: 'center' }
                )
              )}
            </FieldOptions>
          )
        })}
      </FieldOptionsRows>
    )
  }

  const renderTappableField = (field, iconFn, optionsRowFormat = null) => {
    const renderOption = (option, styles) => {
      iconFn = iconFn || ((option, selected) => (selected ? `selected-${option.value}` : `inactive-${option.value}`))
      const selectedVal = values[field.id] || null
      const selected = selectedVal === option.value
      const iconName = iconFn(option, selected)
      return renderTappableOption(field, iconName, selected, option, styles)
    }

    if (optionsRowFormat) {
      return renderTappableFieldRows(field, iconFn, optionsRowFormat, renderOption)
    }

    return <FieldOptions key={field.id}>{field.options.map((option) => renderOption(option))}</FieldOptions>
  }

  const renderNumberField = (field, placeholder, label) => (
    <NumberField key={field.id}>
      <div className="row">
        <input
          //autoFocus={true}
          //onTouchStart={(e) => e.target.focus()}
          type="text" // number or // tel ?
          className="input"
          inputMode="numeric" // Ensures mobile devices opt for numeric keyboards
          pattern="[0-9]*" // iOS looks at the pattern attribute to decide keyboard type
          placeholder={placeholder}
          min={field.min}
          max={field.max}
          onKeyUp={e => (e.target.value ? e.target.value = e.target.value.replace(/[^0-9]/gi, '') : false)}
          onChange={e => setValue(field.id, +e.target.value.slice(0, 3))}
          value={(values[field.id] || '').toString() || ''}
        />
        <div className="label">{t(label)}</div>
        <Icon name="checkmark-circle" className={(values[field.id] || 0) > 0 ? 'valid' : 'invalid'} />
      </div>
    </NumberField>
  )
  const renderHeightInches = (field) => (
    <NumberField key={field.id}>
      <div className="row">
        <input
          type="text" // Change this to text to make pattern effective
          className="input"
          inputMode="numeric" // Ensures mobile devices opt for numeric keyboards
          pattern="[0-9]*" // iOS looks at the pattern attribute to decide keyboard type
          placeholder="--"
          onChange={(e) => {
            setFeet(+e.target.value)
            setValue(field.id, +e.target.value * 12 + inches)
          }}
          value={hasFeet ? feet : ''}
        />
        <div className="label">{t('feet')}</div>
        <input
          type="text" // Again, text type to make pattern effective
          className="input smaller"
          inputMode="numeric"
          pattern="[0-9]*"
          placeholder="--"
          onChange={(e) => {
            setInches(+e.target.value)
            setValue(field.id, feet * 12 + +e.target.value)
          }}
          value={hasInches ? inches : ''}
        />
        <div className="label">{t('inches')}</div>
        <Icon name="checkmark-circle" className={hasFeet && hasInches ? 'valid' : 'invalid'} />
      </div>
    </NumberField>
  )

  const renderHeightInches_LastProduction = (field) => (
    <NumberField key={field.id}>
      <div className="row">
        <input
          type="number"
          className="input"
          pattern="[4-7]*"
          placeholder="--"
          onChange={(e) => {
            setFeet(+e.target.value)
            setValue(field.id, +e.target.value * 12 + inches)
          }}
          value={hasFeet ? feet : ''}
        />
        <div className="label">{t('feet')}</div>
        <input
          type="number"
          className="input smaller"
          placeholder="--"
          pattern="[0-9]{1,2}"
          onChange={(e) => {
            setInches(+e.target.value)
            setValue(field.id, feet * 12 + +e.target.value)
          }}
          value={hasInches ? inches : ''}
        />
        <div className="label">{t('inches')}</div>
        <Icon name="checkmark-circle" className={hasFeet && hasInches ? 'valid' : 'invalid'} />
      </div>
    </NumberField>
  )

  const renderDateField = (field) => (
    <DateInput
      formatDate={(date) => date.toLocaleDateString()}
      parseDate={(string) => new Date(string)}
      placeholder="MM/DD/YYYY"
      onChange={(date) => setValue(field.id, date)}
      value={values[field.id]}
      maxDate={new Date()}
    />
  )

  const renderCustomField = (field) => (
    <TextField key={field.id}>
      <input
        type="text"
        placeholder={field?.placeholder}
        className="input"
        onChange={(e) => setValue(field.id, e.target.value)}
        value={values[field.id] || ''}
      />
    </TextField>
  )

  const renderField = (field, idx) => {
    let options = <span />
    const id = field.id
    const [placeholder, label] =
      {
        age_years: ['00', 'years old'],
        weight_lbs: ['000', 'pounds'],
        weight_kg: ['00', 'kg'],
        hba1c: [' ', ''],
        diastolic: [' ', ''],
        systolic: [' ', ''],
        height_cm: ['00', 'cm'],
        weeks_pregnant: ['##', 'weeks pregnant'],
        prepregnancy_weight_lbs: ['000', 'pounds'],
        prepregnancy_weight_kg: ['00', 'kg']
      }[id] || []
    if (['pregnant', 'prenatal_vitamins', 'number_of_babies', 'weight_trend', 'breastfeeding', 'high_bp', 'high_bs'].includes(id)) {
      options = renderTappableField(field, (option, selected) => `inactive-${option.value}`)

/*      if (id === 'high_bp') {
        options = (
            <>
              <span style={{display:'block', textAlign:'center', fontSize: 24, margin: 'auto auto 5px', paddingTop: 0, paddingBottom: 0}}>Optional:</span>
              {options}
            </>
        )
      }
      */
    }

    else if (id === 'gender') {
      options = renderTappableField(field, (option, selected) => `inactive-${option.value}`, [
        ['male', 'female', 'non_binary'],
        ['prefer_to_self_describe', 'prefer_not_to_answer']
      ])
    } else if (id === 'activity_level') {
      options = renderTappableField(field, (option, selected) => `available-${option.value}`)
    } else if (id === 'height_inches') {
      options = renderHeightInches(field)
    } else if (id === 'baby_dob') {
      options = renderDateField(field)
    } else if (['custom_field'].includes(id)) {
      options = renderCustomField(field)
    } else if (id === 'bmi') {
      if (!(values.weight_lbs && hasFeet && hasInches)) {
        return null
      }

      options = (
        <DisplayField key={field.id}>
          <div className="row">
            <BMI gender={values.gender} height={values.height_inches} weight={values.weight_lbs} metric={false} />
          </div>
        </DisplayField>
      )
    } else {
      if (placeholder) {
        options = renderNumberField(field, placeholder, label)
      }
    }

    const error = [...(errors || []), ...localErrors].find((e) => e.key === id)
    const hidden = !field.display(values) || (nbty && ['custom_field', 'weight_trend'].includes(field.id))

    return (
      <Field
        key={field.id}
        className={['breastfeeding', 'activity_level'].includes(field.id) ? 'vertical' : null}
        style={hidden ? { display: 'none' } : {}}
      >
        <FieldLabel>{field.label}</FieldLabel>
        <FieldContent>
          {error && <FieldError>{error.message}</FieldError>}
          {options}
        </FieldContent>
      </Field>
    )
  }

  if (formIsPrefilled) {
    return <Wrap />
  }

  if (showConferMed) {
    return (
      <ConferMedUserInfo
        onProgressChange={onProgressChange}
        onComplete={(value) =>
          submitValues({
            ...values,
            custom_field: JSON.stringify({ ...JSON.parse(value), dateOfBirth: values.custom_field })
          })
        }
      />
    )
  }

  if (showNaturesBounty) {
    return (
      <NaturesBountyUserInfo
        dietType={idDietType}
        onProgressChange={onProgressChange}
        onComplete={(value) => submitValues({ ...values, custom_field: value })}
      />
    )
  }

  const hiddenConditionalFieldIds = _.compact(
    formFields.map(({ id, display }) => (conditionalUserInfoFields.includes(id) && !display(values) ? id : null))
  )

  const validateValues = () => {
    const validationErrors = []
    const excludedIds = ['hba1c', 'systolic', 'diastolic', 'high_bp', 'high_bs', 'custom_field']
    formFields
      .filter((field) => field.type !== 'display' && !excludedIds.includes(field.id))
      .forEach(({ id, label, options, min, minError, max, maxError, missingError }) => {
        if (hiddenConditionalFieldIds.includes(id)) {
          return
        }

        if (typeof values[id] === 'undefined') {
          validationErrors.push({
            key: id,
            message: missingError || t(options ? 'Please select an option' : 'Please enter a value')
          })
          return
        }

        if (typeof min !== 'undefined' && values[id] < min) {
          validationErrors.push({ key: id, message: minError })
        }

        if (typeof max !== 'undefined' && values[id] > max) {
          validationErrors.push({ key: id, message: maxError })
        }
      })

    setLocalErrors(validationErrors)
    return !validationErrors.length
  }

  const onNextButtonClick = () => {
    if (validateValues()) {
      if (nbty) {
        setShowNaturesBounty(true)
        return
      }

      if (confermed) {
        setShowConferMed(true)
        return
      }

      onProgressChange('UserInfo', 1)
      submitValues(values)
    }
  }

  return (
    <>
      {nbty && (
        <div>
          <Header>{t("Final details to know what's just right for you")}</Header>
        </div>
      )}
      <Wrap>
        <Fields>{formFields.map((field, idx) => renderField(field, idx))}</Fields>
        <NextButton {...props} onClick={onNextButtonClick} />
      </Wrap>
    </>
  )
}

export default UserInfo
