import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'
import { DateInput } from '@blueprintjs/datetime'

import { Header as _Header } from 'app/components/Core'
import BMI from 'app/components/BMI'
import Icon from 'app/components/Icon'
import ConferMedUserInfo from 'app/custom/confermed/ConferMedUserInfo'
import NaturesBountyUserInfo from 'app/custom/naturesbounty/NaturesBountyUserInfo'
import * as constants from 'app/lib/constants'
import colors from 'app/lib/colors'
import t from 'app/lib/i18n'
import { getWorkflowUserInfo } from 'app/lib/integration'
import { getUserInfoFields, conditionalUserInfoFields } from './logic'
import NextButton from './NextButton'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    max-width: 550px;
    margin: 0 auto;
  }

  .bp3-popover-target {
    width: 100%;
  }

  .bp3-input {
    font-size: 32px;
    border: 1px #dfe3e3 solid;
    padding: 5px 17px;
    color: #575959;
    line-height: 1.15;
    outline: none;
    height: 100%;
    box-shadow: none;
    border-radius: 0;

    :focus,
    &.bp3-active {
      box-shadow: none;
    }
  }
`

const Header = styled(_Header)`
  font-size: 18px;
  margin: 0px 12px 15px;
  padding: 0;
`

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
`

const FieldOptions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FieldOptionsRows = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const FieldContent = styled.div`
  box-sizing: border-box;
  width: 100%;
`

const FieldLabel = styled.div`
  font-weight: bold;
  color: ${colors.text};
  padding: 12px 5px 12px 0;
  box-sizing: border-box;
  min-width: 120px;
  width: 120px;
  max-width: 120px;

  @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
    min-width: 80px;
    width: 80px;
    max-width: 80px;
  }
`

const FieldError = styled.div`
  color: ${colors.white};
  background-color: ${colors.red};
  margin: 12px 0;
  padding: 12px;
  border-radius: 4px;
`

const TappableOption = styled.div`
  cursor: pointer;
  border: 1px ${colors.lightGray3} solid;
  padding: 12px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 5px;
  color: ${colors.text};
  font-size: 18px;

  &.selected {
    background-color: ${(props) => props.theme.primary};
    border-color: ${(props) => props.theme.primary};
    color: white;
    z-index: 1;

    .description {
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .row {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
  }

  .description {
    font-size: 15px;
    color: ${lighten(0.2, colors.text)};
  }

  .icon-wrap {
    padding-right: 8px;

    > * {
      font-size: inherit;
    }
  }
`

const Field = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  padding: 6px 12px;

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    :not(.vertical) {
      ${FieldOptions} {
        flex-direction: row;
      }
    }

    ${TappableOption} {
      margin: 0;

      :not(.selected):hover {
        color: ${(props) => props.theme.primary};
      }
    }

    :not(.vertical) ${TappableOption} {
      margin-left: -1px;

      .row {
        justify-content: center;
      }
    }

    &.vertical {
      ${TappableOption} {
        margin-top: -1px;
      }
    }
  }
`

const NumberField = styled.div`
  border: 1px ${colors.lightGray3} solid;
  padding: 0 12px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 5px;
  color: ${colors.lightGray};
  font-size: 18px;

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    margin-bottom: 0;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon {
    color: ${(props) => props.theme.primary};

    &.invalid {
      opacity: 0;
    }
  }

  .input {
    font-size: 32px;
    padding: 5px;
    border: 0;
    width: 74px;
    background-color: transparent;
    color: ${colors.darkGray};
    outline: none;

    &.smaller {
      width: 50px;
    }

    &::placeholder {
      color: ${colors.lightGray};
    }
  }

  .label {
    font-size: 18px;
    flex: 1;
  }
`

const DisplayField = styled.div`
  border: 1px solid ${colors.lightGray3};
  padding: 10px;
`

const TextField = styled.div`
  border: 1px solid ${colors.lightGray3};

  input {
    font-size: 32px;
    padding: 5px 12px;
    border: 0;
    width: 100%;
    background-color: transparent;
    color: #575959;
    outline: none;
  }
`


const QuestionInput = (props) => {
  const {
    partner,
    errors,
    step: { fields = [], options = [], id_diet_type: idDietType },
    onProgressChange
  } = props
  console.log('QuestionInput fields ', fields)
  const [values, setValues] = useState({})
  const [localErrors, setLocalErrors] = useState([])
  const setValue = (field, value) => {
    setValues(prevValues => ({
      ...prevValues,
        key: field.question_id,  // Using 'question_id' from field as 'key'
        label: value.toString() // Updating the 'label' key with the new value
    }));
  };
  const formFields = fields // getUserInfoFields(partner)
  const submitValues = (values) => props.onSubmit(_.omit(values))
  useEffect(() => {
    onProgressChange('QuestionInput', 0)
  }, [])
 
  const renderNumberField = (field, placeholder, label) => (
    <NumberField key={field.id}>
      <div className="row">
        <input
          autoFocus={true}
          onTouchStart={() => this.focus()}
          type="text"
          className="input"
          placeholder={placeholder}
          min={field.min}
          max={field.max}
          onKeyUp={e => (e.target.value ? e.target.value = e.target.value.replace(/[^0-9]/gi, '') : false)}
          onChange={e => setValue(field, +e.target.value.slice(0, 3))}
          value={(values.label) || ''}
        />
        <div className="label">{t(label)}</div>
        <Icon name="checkmark-circle" className={(values[field.id] || 0) > 0 ? 'valid' : 'invalid'} />
      </div>
    </NumberField>
  )
  const renderCustomField = (field) => (
    <TextField key={field.key}>
      <input
        type="text"
        className="input"
        onChange={(e) => setValue(field, e.target.value)}
        value={values.label || ''}
      />
    </TextField>
  )
  const renderField = (field, idx) => {
    const fieldType = field.type;
    let options = <span />;
    
    if (fieldType === 'string') {
      options = renderCustomField(field);
    } else if (fieldType === 'number') {
      options = renderNumberField(field, field.placeholder || '', field.label || '');
    }
    
    const error = [...(errors || []), ...localErrors].find((e) => e.key === field.key);
    const hidden = field.display && !field.display(values);
    
    return (
      <Field
        key={field.key}
        className={['breastfeeding', 'activity_level'].includes(field.key) ? 'vertical' : null}
        style={hidden ? { display: 'none' } : {}}
      >
        <FieldLabel>{field.label}</FieldLabel>
        <FieldContent>
          {error && <FieldError>{error.message}</FieldError>}
          {options}
        </FieldContent>
      </Field>
    );
  }
  const validateValues = () => {
    const validationErrors = []
    formFields
      .filter((field) => field.type !== 'display' && field.key !== 'custom_field')
      .forEach(({ key, label, options, min, minError, max, maxError, missingError }) => {
        
        if (typeof values[key] === 'undefined') {
          validationErrors.push({
            key: key,
            message: missingError || t(options ? 'Please select an option' : 'Please enter a value')
          })
          return
        }

        if (typeof min !== 'undefined' && values[key] < min) {
          validationErrors.push({ key: key, message: minError })
        }

        if (typeof max !== 'undefined' && values[key] > max) {
          validationErrors.push({ key: key, message: maxError })
        }
      })
    setLocalErrors(validationErrors)
    return !validationErrors.length
  }
  const onNextButtonClick = () => {
    if (validateValues()) {
      onProgressChange('QuestionInput', 1)
      submitValues(values)
    }
  }
  return (
    <>
      <Wrap>
        <Fields>{formFields.map((field, idx) => renderField(field, idx))}</Fields>
        <NextButton {...props} onClick={onNextButtonClick} />
      </Wrap>
    </>
  )
}

export default QuestionInput
