import _ from 'lodash'
import t from 'app/lib/i18n'

const _dietTypes = [
  {
    name: 'Nothing in Particular',
    description:
      'Includes a variety of whole fruits and vegetables, predominantly whole grains, nuts and seeds, beans and lentils, free-range eggs, non-fat dairy products and plant-based alternatives, and sustainably produced fish, lean meats, and poultry.',
    code: null
  },
  {
    name: 'American',
    description:
      'Typically includes a variety of whole fruits and vegetables, predominantly whole grains, nuts and seeds, beans and lentils, free-range eggs, non-fat dairy products and plant-based alternatives, and sustainably produced fish, lean meats, and poultry.',
    code: 'AME'
  },
  {
    name: 'No Red Meat',
    description:
      'Typically includes a variety of whole fruits and vegetables, predominantly whole grains, nuts and seeds, beans and lentils, free-range eggs, non-fat dairy products and plant-based alternatives, and sustainably produced fish and poultry.',
    code: 'NRM'
  },
  {
    name: 'Flexitarian',
    description:
      'Typically includes a variety of fresh produce, whole grains, beans, nuts, seeds, pasture-raised eggs, limited amounts of high-quality, sustainably produced lean meats, poultry, and fish, and non-fat dairy products or plant-based dairy alternatives.',
    code: 'FLX'
  },
  {
    name: 'Low-Carb',
    description:
      'Typically includes meats, poultry, seafood, eggs, mostly non-starchy vegetables, whole fruits, nuts and seeds, variety of fats, with or without dairy/non-dairy products. Limits grains, legumes, and added sugars.',
    code: 'LOC'
  },
  {
    name: 'Keto',
    description:
      'The ketogenic dietary pattern is designed to cause the metabolic state of ketosis, whereby the body burns predominantly fat for fuel rather than\n' +
      'carbohydrate. The goal macronutrient breakdown is typically <10% calories from net/available carbs (those that are not fiber or sugar alcohols), 20-25% calories from protein, and 70-80% from fat.',
    code: 'KET'
  },
  {
    name: 'Low-Fat',
    description:
      'Typically includes lean meats, poultry, fish, fruits and vegetables, grains, legumes, low-fat dairy products, egg whites, and limited amounts of nuts, seeds, nut butters, olives, avocados, cooking oils, and egg yolks.',
    code: 'LOF'
  },
  {
    name: 'Mediterranean',
    description:
      'Typically includes vegetables, fruits, nuts and seeds, whole grains, legumes, low-fat dairy products, seafood and lean poultry. Emphasis on olive oil, herbs, spices, and red wine (moderation).',
    code: 'MED'
  },
  {
    name: 'Paleo',
    description:
      'Typically includes a variety of meats (e.g. grass-fed, game), wild fish and seafood, free-range eggs, whole fruits and vegetables, nuts and seeds, with or without non-dairy milk. Limits legumes, grains, dairy products, refined sugars and added salt.',
    code: 'PAL'
  },
  {
    name: 'Pescatarian',
    description:
      'Typically includes fresh vegetables and fruits, whole grains, legumes, nuts, seeds, and sustainably sourced fish/seafood. May or may not include eggs and/or dairy. Excludes all other animal products.',
    code: 'PES'
  },
  {
    name: 'Southern',
    description:
      'Based on a Southern American culinary style, typically includes a variety of whole fruits and vegetables, predominantly whole grains, nuts and seeds, beans, non-fat dairy products, lean poultry, pork, beef, fish, and seafood.',
    code: 'SOU'
  },
  {
    name: 'Vegan',
    description:
      'Typically includes 100% plant-based, whole foods -- fresh vegetables and fruits, whole grains, beans and legumes, nuts, and seeds. Excludes all animal products.',
    code: 'VEG'
  },
  {
    name: 'Vegetarian',
    description:
      'Typically includes fresh vegetables and fruits, whole grains, beans and legumes, nuts, seeds, dairy products, eggs, and plant-based meat and dairy alternatives. Excludes all other animal products.',
    code: 'VET'
  },
  {
    name: 'AHA Heart Healthy',
    description:
      'AHA (American Heart Association) Heart Healthy typically includes vegetables, fruits, whole grains, low-fat or non-fat dairy products, lean poultry, fish, nuts, legumes, and non-tropical vegetable oils.',
    code: 'AHA'
  },
  {
    name: 'DASH',
    description:
      'DASH (Dietary Approaches to Stop Hypertension) typically includes vegetables, fruits, whole grains, nuts, seeds, legumes, mostly non-fat dairy products, lean meats, poultry and fish, non-tropical vegetable oils. Emphasis is on low-sodium foods.',
    code: 'DSH'
  },
  {
    name: 'TLC',
    description:
      'TLC (Therapeutic Life Changes) typically includes vegetables, fruits, whole grains, low-fat or non-fat dairy products, lean meats, fish, egg whites, and non-tropical vegetable oils. Emphasis is on oats, barley, beans, lentils, peas, nuts and seeds.',
    code: 'TLC'
  },
  {
    name: 'MIND',
    description:
      'MIND (Mediterranean-DASH Intervention for Neurodegenerative Delay) typically includes vegetables, fruits, whole grains, with or without dairy products, legumes, nuts, fish, and lean poultry. Emphasis on green leafy vegetables, beans, berries, olive oil, and wine (moderation).',
    code: 'MND'
  },
  {
    name: 'Whole Foods Plant-Based',
    description:
      'Typically includes predominantly whole foods, all plant based, including vegetables, fruits, intact whole grains, nuts, seeds, and legumes, with minimal amounts of processed foods (such as soyfoods, whole grain flour-based products) and no added oils, concentrated sugars, or concentrated fats.',
    code: 'WFP'
  },
  {
    name: 'Whole Foods Plant-Based Fat-Restricted',
    description:
      'Typically includes vegetables, fruits, intact whole grains, and legumes. It also includes minimal amounts of processed foods (such as soyfoods, whole grain flour-based products), and nuts and seeds, and has no added oils, concentrated sugars, or concentrated fats. Fat content limited to about 10%.',
    code: 'WFR'
  },
  {
    name: 'Mexican',
    description:
      'This Latin American dietary pattern is based heavily on corn/maize, chile, and beans. This dietary pattern typically includes wheat and other grains, meats, poultry, fish/seafood, eggs, fruits, vegetables, beans, nuts, seeds, sweeteners, and dairy products. May include highly processed foods, beverages and ingredients.',
    code: 'MEX'
  },
  {
    name: 'Vietnamese',
    description:
      'The Vietnamese Dietary pattern, reflective of the traditional cuisine of Vietnam, is based heavily on rice, meat, fish, and vegetables. The dietary pattern typically includes rice and rice-derived products, fruits, vegetables, nuts, seeds, and eggs.',
    code: 'VTM'
  },
  {
    name: 'Korean',
    description:
      'The Korean dietary pattern is reflective of the traditional cuisine of South Korea with a strong emphasis on rice, vegetables, legumes, and meats, particularly beef, chicken, and pork. The dietary pattern also typically includes a variety of fermented foods like kimchi (fermented vegetables), noodles, tofu, soy products, and seafood. Meals are often accompanied by side dishes (banchan), e.g. seasoned vegetables, eggs, and small portions of meat or fish. The use of seasonings such as soy sauce, sesame oil, garlic, ginger, and gochujang (fermented chili paste) is common.',
    code: 'KOR'
  },
  {
    name: 'Latin Style Vegetarian',
    description:
      'The Latin Style Vegetarian dietary pattern, reflective of the traditional cuisine of Mexico, is based heavily on corn, rice, vegetables, and beans. It typically also includes wheat and other grains, eggs, fruits, vegetables, beans, nuts, seeds, sweeteners, and dairy products. May include highly processed foods, beverages, and ingredients.',
    code: 'MEX_V'
  },
  {
    name: 'South Asian',
    description:
      'The South Asian Non-Vegetarian dietary pattern is based on the Indian cuisine with an emphasis on flatbreads (e.g. roti, chapati, naan), rice, legumes (e.g. chickpeas, lentils, peas), vegetables (e.g. spinach, potatoes, cauliflower), fruits (e.g. mango, banana, papaya), dairy products (e.g. yogurt, milk, paneer), nuts (e.g. peanuts, cashews), eggs, meats (e.g. chicken, mutton, beef), fish and seafood. Use of whole and ground spices (e.g. cumin, turmeric, chili, asafoetida), fresh herbs (e.g. coriander, mint), and hot chili peppers are commonly added for flavor.',
    code: 'SA'
  },
  {
    name: 'South Asian Vegetarian',
    description:
      'The South Asian Vegetarian dietary pattern is based on the Indian cuisine with an emphasis on flatbreads (e.g. roti, chapati, naan), rice, legumes (e.g. chickpeas, lentils, peas), vegetables (e.g. spinach, potatoes, cauliflower), fruits (e.g. mango, banana, papaya), dairy products (e.g. yogurt, milk, paneer), nuts (e.g. peanuts, cashews), and in some instances, eggs. Use of whole and ground spices (e.g. cumin, turmeric, chili, asafoetida), fresh herbs  (e.g. coriander, mint) ), and hot chili peppers are commonly added for flavor.',
    code: 'SA_V'
  },
  {
    name: 'Continental Europe South',
    description:
      'The Continental European dietary pattern is based on the cuisines common in the countries of Italy, France, Spain, Belgium and Switzerland.',
    code: 'CES'
  },
  {
    name: 'Continental Europe North',
    description:
      'The Northern European dietary pattern is based on the cuisines common in the countries of Sweden, Norway, Denmark, and Finland.',
    code: 'CEN'
  },
  {
    name: 'Portfolio',
    description:
      'The Portfolio diet is a modified vegan diet that includes a "portfolio" of foods shown to lower LDL cholesterol. The diet is based on soluble-fiber rich fruits and vegetables, whole grains, beans, nuts, seeds, stanol-enriched fats, and soy products.',
    code: 'POF'
  },
  {
    name: 'Ornish',
    description:
      'The Ornish plan for reversing heart disease includes vegetables, fruits, whole grains, legumes, non-fat dairy products, egg whites, and limited nuts and seeds. Emphasis is on plant foods and low-fat foods. Total fat percentage of calories ideally 10%, protein 20%.',
    code: 'ORN'
  },
  {
    name: 'Low FODMAP',
    description:
      'The Low FODMAP diet includes foods known to be low in FODMAPs, which are certain types of carbohydrates that may aggravate symptoms of Irritable Bowel Syndrome. Meat, fish, poultry, and eggs are allowed. Dairy is restricted due to lactose, but lactose-free dairy products, most plant-based dairy alternatives, fermented dairy products, and small amounts of certain hard cheeses are acceptable. Nuts and seeds are limited to about 1 oz/day. Beans are generally restricted to certain types, and a small portion per day. Gluten-containing grains are eliminated. Low FODMAP fruits, vegetables, herbs, and sweeteners, are acceptable.',
    code: 'LFM'
  },
  {
    name: 'American Gluten-Free',
    description:
      'This dietary pattern includes a variety of whole fruits and vegetables, grown locally where possible, whole grains (e.g. quinoa, brown rice), nuts and seeds, beans and lentils, free-range eggs, non-fat dairy products and plant-based alternatives, lean, sustainably produced poultry and fish, and water as the main beverage. Excludes gluten-containing grains (e.g. wheat, farro, rye).',
    code: 'AME_GF'
  },
  {
    name: 'Vegetarian Gluten-Free',
    description:
      'This dietary pattern includes fresh vegetables and fruits, whole grains, beans, nuts and seeds, eggs, plant-based meat and dairy alternatives, and non-fat dairy products, with minimal food packaging. Excludes gluten-containing grains (e.g. wheat, farro, rye).',
    code: 'VET_GF'
  },
  {
    name: 'Vegan Gluten-Free',
    description:
      'This dietary pattern includes whole foods -- fresh vegetables and fruits, whole grains, beans and legumes, nuts, and seeds, plus some minimally processed soy and nut-based products, and water as the main beverage, with minimal use of food packaging. Excludes gluten-containing grains (e.g. wheat, farro, rye).',
    code: 'VEG_GF'
  },
  {
    name: 'Caribbean-West Indies',
    description:
      'The West Indies Caribbean dietary pattern is based on the traditional cuisines of Trinidad, Jamaica, Guyana, Tobago, and Haiti. There is emphasis on soups and stews, root vegetables, rice, bread, coconut products, tropical fruits, fish, and cow’s milk. Traditionally, the mid-day meal (“lunch”) is the main meal, while the evening meal (“dinner”) consists of lighter fare.',
    code: 'CAR_WI'
  },
  {
    name: 'DASH CKD',
    description:
      'Vegetables, fruits, whole grains, nuts/seeds, legumes, some non-fat dairy products, non-dairy milk, lean meats, poultry and fish, non-tropical vegetable oils',
    code: 'DSH_CKD'
  },
  {
    name: 'DASH Gluten-Free',
    description: 'Vegetables, fruits, gluten-free whole grains (e.g. quinoa, buckwheat, brown rice, certified gluten-free oats), nuts/seeds, legumes, mostly non-fat dairy products, lean meats, poultry and fish, non-tropical vegetable oils. Emphasis is on low-sodium foods.',
    code: 'DSH_GF'
  },
  {
    name: 'American Gluten-Free Dairy-Free',
    description:
      'Fresh vegetables and fruits, gluten-free whole grains (e.g. quinoa, buckwheat, brown rice, certified gluten-free oats), beans, nuts and seeds, eggs, high-quality meat, poultry, fish, and plant-based dairy alternatives',
    code: 'AME_GFDF'
  },
  {
    name: 'Flexitarian Gluten-Free Dairy-Free',
    description:
      'Fresh vegetables and fruits, gluten-free whole grains (e.g. quinoa, buckwheat, brown rice, certified gluten-free oats), beans, nuts and seeds, eggs, limited amount of high-quality meat, poultry, and/or fish, and plant-based dairy alternatives',
    code: 'FLX_GFDF'
  },
  {
    name: 'Vegetarian Gluten-Free Dairy-Free',
    description:
      'Fruits, vegetables, gluten-free grains (e.g. quinoa, brown rice), legumes, nuts, seeds, dairy substitutes, eggs, meat substitutes',
    code: 'VET_GFDF'
  },
  {
    name: 'Flexitarian Gluten-Free',
    description: 'Fresh vegetables and fruits, gluten-free whole grains (e.g. quinoa, buckwheat, brown rice, certified gluten-free oats), beans, nuts and seeds, pasture-raised eggs, limited amounts of high-quality, sustainably produced poultry and/or fish, and non-fat dairy or plant-based dairy alternatives',
    code: 'FLX_GF'
  },
  {
    name: 'Mediterranean CKD',
    description: 'Vegetables and fruits, nuts, seeds, whole grains, beans and legumes, cage-free eggs, some low-fat dairy products or plant-based alternatives, seafood and lean poultry, olives, olive oil, dark chocolate (optional)',
    code: 'MED_CKD'
  },
  {
    name: 'Mind CKD',
    description: 'Vegetables, fruits, whole grains, dairy products (optional), legumes, nuts, fish, and lean poultry, with emphasis on green leafy vegetables, beans, berries, olive oil',
    code: 'MND_CKD'
  },
  {
    name: 'Vegan CKD',
    description: 'Fresh vegetables and fruits, whole grains, beans and legumes, nuts and seeds, plant-based dairy alternatives, with minimal food packaging and emphasis on lower potassium and phosphorus foods',
    code: 'VEG_CKD'
  },
  {
    name: 'Menopause Vegan',
    description: 'Vegetables, fruits, whole grains, non-dairy products, legumes, nuts, seeds, with an emphasis on green leafy vegetables, beans, berries, and soybean-based foods (e.g. soybeans, tofu, edamame, soy milk)',
    code: 'VEG_MNP'
  },
  {
    name: 'PCOS Vegan',
    description: 'Vegetables, fruits, whole grains, non-dairy products, legumes, nuts, seeds, with an emphasis on green leafy vegetables, beans, berries, and soybean-based foods (e.g. soybeans, tofu, edamame, soy milk)',
    code: 'VEG_PCOS'
  },
  {
    name: 'Vegetarian CKD',
    description: 'Vegetables and fruits, whole grains, beans and legumes, nuts, seeds, dairy products, eggs, and plant-based meat and dairy alternatives',
    code: 'VET_CKD'
  },
  {
    name: 'Vegetarian Gluten-Free',
    description: 'Fresh vegetables and fruits, whole grains, beans, nuts and seeds, eggs, plant-based meat and dairy alternatives, and non-fat dairy products',
    code: 'VET_GF'
  },
  {
    name: 'Menopause Vegetarian',
    description: 'Vegetables, fruits, whole grains, dairy products (optional), eggs, legumes, nuts, seeds, with emphasis on green leafy vegetables, beans, berries, and soybean-based foods (e.g. tofu, soy milk, soy yogurt), healthy oils (e.g. olive oil)',
    code: 'VET_MNP'
  },
  {
    name: 'PCOS Vegetarian',
    description: 'Vegetables, fruits, whole grains, dairy products (optional), eggs, legumes, nuts, seeds, with emphasis on green leafy vegetables, beans, berries, and soybean-based foods (e.g. tofu, soy milk, soy yogurt), healthy oils (e.g. olive oil)',
    code: 'VET_PCOS'
  },
  {
    name: 'Whole Foods Plant-Based CKD',
    description: 'Fresh vegetables and fruits, whole grains, beans and legumes, nuts and seeds, plant-based dairy alternatives, and emphasis on lower sodium, potassium and phosphorus foods',
    code: 'WFP_CKD'
  },
  {
    name: 'Whole Foods Plant-Based Gluten-Free',
    description: 'Fresh vegetables, fruits, gluten-free whole grains (e.g. quinoa, buckwheat, brown rice, certified gluten-free oats), legumes, nuts and seeds',
    code: 'WFP_GF'
  },
  {
    name: 'Whole Foods Plant-Based Menopause',
    description: 'Vegetables, fruits, whole grains, non-dairy products, legumes, nuts, seeds, with emphasis on green leafy vegetables, beans, berries, and soybean-based foods (e.g. soybeans, tofu, edamame, soy milk)',
    code: 'WFP_MNP'
  },
  {
    name: 'Whole Foods Plant-Based PCOS',
    description: 'Vegetables, fruits, whole grains, non-dairy products, legumes, nuts, seeds, with emphasis on green leafy vegetables, beans, berries, and soybean-based foods (e.g. soybeans, tofu, edamame, soy milk)',
    code: 'WFP_PCOS'
  }
]

export const getDietTypes = () => _dietTypes.map((dt) => ({ ...dt, name: t(dt.name), description: t(dt?.description) }))

export const getScreenerPaths = (disableStyleSelection) => [
  [['meat'], ['meat', 'yes'], ['grains', 'yes'], ...(disableStyleSelection ? [] : [['style']])],
  [['meat'], ['meat', 'yes'], ['grains', 'no'], ['dairy', 'yes'], ...(disableStyleSelection ? [] : [['style']])],
  [['meat'], ['meat', 'yes'], ['grains', 'no'], ['dairy', 'no'], ['dairy']],

  [['meat'], ['meat', 'sometimes'], ['grains', 'yes']],
  [['meat'], ['meat', 'sometimes'], ['grains', 'no'], ['dairy']],

  [['meat'], ['meat', 'no'], ['poultry', 'yes']],
  [['meat'], ['meat', 'no'], ['poultry', 'no'], ['fish', 'yes']],
  [['meat'], ['meat', 'no'], ['poultry', 'no'], ['fish', 'no'], ['dairy']]
]

const _bmiStatuses = [
  {
    id: 'underweight',
    label: 'Underweight',
    color: '#3498db',
    isActive: (bmi) => bmi < 18.5,
    description:
      'You’re in the underweight range. Adding a few pounds can strengthen your immune system and help you feel less tired.'
  },
  {
    id: 'normal',
    label: 'Normal',
    color: '#2ecc71',
    isActive: (bmi) => bmi >= 18.5 && bmi < 25,
    description: 'You’re in the healthy range. Keep up the good work to maintain your weight!'
  },
  {
    id: 'overweight',
    label: 'Overweight',
    color: '#f39c12',
    isActive: (bmi) => bmi >= 25 && bmi < 30,
    description:
      'You’re in the overweight range. Losing a few pounds can reduce your chance of health problems like heart disease, type 2 diabetes, and high blood pressure. If you have a very muscular build, though, you could have “overweight” status but actually be in a healthy weight range.'
  },
  {
    id: 'obesity1',
    label: 'Obesity',
    color: '#e74c3c',
    isActive: (bmi) => bmi >= 30 && bmi < 35,
    description:
      'You’re in the obesity range. Losing weight will likely reduce your risk of serious health problems like heart disease, diabetes, high blood pressure, cancer, and sleep apnea.'
  },
  {
    id: 'obesity2',
    label: 'Obesity',
    color: '#e74c3c',
    isActive: (bmi) => bmi >= 35 && bmi < 40,
    description:
      'You’re in the obesity range. Losing weight will likely reduce your risk of serious health problems like heart disease, diabetes, high blood pressure, cancer, and sleep apnea.'
  },
  {
    id: 'obesity3',
    label: 'Obesity',
    color: '#e74c3c',
    isActive: (bmi) => bmi >= 40,
    description:
      'You’re in the obesity range. Losing weight will likely reduce your risk of serious health problems like heart disease, diabetes, high blood pressure, cancer, and sleep apnea.'
  }
]

const getBMIStatuses = () =>
  _bmiStatuses.map((status) => ({ ...status, label: t(status.label), description: t(status.description) }))

const bmiPercentiles = [
  [0, 14.2, 14.1],
  [1, 17.7, 18.1],
  [2, 18.7, 19.3],
  [3, 19.2, 19.9],
  [4, 19.5, 20.4],
  [5, 19.8, 20.8],
  [6, 20.1, 21.1],
  [7, 20.4, 21.4],
  [8, 20.7, 21.8],
  [9, 21, 22],
  [10, 21.3, 22.3],
  [11, 21.4, 22.5],
  [12, 21.5, 22.6],
  [13, 21.8, 22.8],
  [14, 21.9, 23],
  [15, 22.1, 23.3],
  [16, 22.2, 23.4],
  [17, 22.4, 23.6],
  [18, 22.5, 23.8],
  [19, 22.7, 23.9],
  [20, 22.9, 24],
  [21, 23.1, 24.2],
  [22, 23.2, 24.3],
  [23, 23.4, 24.5],
  [24, 23.6, 24.7],
  [25, 23.7, 24.8],
  [26, 23.9, 25],
  [27, 24.1, 25.1],
  [28, 24.3, 25.3],
  [29, 24.5, 25.4],
  [30, 24.6, 25.6],
  [31, 24.8, 25.7],
  [32, 25, 25.8],
  [33, 25.2, 25.9],
  [34, 25.3, 26.1],
  [35, 25.5, 26.2],
  [36, 25.7, 26.4],
  [37, 25.9, 26.5],
  [38, 26.1, 26.6],
  [39, 26.2, 26.7],
  [40, 26.4, 26.8],
  [41, 26.6, 26.9],
  [42, 26.8, 27],
  [43, 26.9, 27.1],
  [44, 27.1, 27.3],
  [45, 27.3, 27.4],
  [46, 27.4, 27.5],
  [47, 27.6, 27.6],
  [48, 27.8, 27.7],
  [49, 28, 27.9],
  [50, 28.2, 28],
  [51, 28.4, 28.1],
  [52, 28.6, 28.3],
  [53, 28.8, 28.4],
  [54, 28.9, 28.5],
  [55, 29.1, 28.7],
  [56, 29.3, 28.8],
  [57, 29.5, 28.9],
  [58, 29.8, 29.1],
  [59, 29.9, 29.2],
  [60, 30.2, 29.4],
  [61, 30.4, 29.5],
  [62, 30.6, 29.7],
  [63, 30.8, 29.9],
  [64, 31.1, 30],
  [65, 31.2, 30.2],
  [66, 31.5, 30.4],
  [67, 31.7, 30.5],
  [68, 31.9, 30.7],
  [69, 32.1, 30.9],
  [70, 32.3, 31.1],
  [71, 32.6, 31.3],
  [72, 33, 31.5],
  [73, 33.2, 31.7],
  [74, 33.4, 31.9],
  [75, 33.7, 32.1],
  [76, 34, 32.3],
  [77, 34.3, 32.6],
  [78, 34.7, 32.8],
  [79, 35, 33],
  [80, 35.4, 33.2],
  [81, 35.6, 33.4],
  [82, 36, 33.7],
  [83, 36.4, 33.8],
  [84, 36.8, 34.2],
  [85, 37.3, 34.6],
  [86, 37.7, 34.8],
  [87, 38.2, 35.2],
  [88, 38.7, 35.5],
  [89, 39.2, 36],
  [90, 39.8, 36.6],
  [91, 40.5, 37],
  [92, 41.4, 37.6],
  [93, 42.2, 38.2],
  [94, 43, 39.3],
  [95, 43.9, 40.4],
  [96, 45.3, 42],
  [97, 47.4, 43.9],
  [98, 49.5, 46.1],
  [99, 54, 49.8],
  [100, 82.9, 74.1]
].map(([percentile, female, male]) => ({ percentile, female, male }))

export const getBMI = (gender, height, weight, isMetric) => {
  if (!height || !weight) {
    return {}
  }

  const bmi = (isMetric ? 1 : 703) * (weight / (height * height))
  const status = getBMIStatuses().find(({ isActive }) => isActive(bmi))

  const percentileIndex = bmiPercentiles.findIndex((percentile) => percentile[gender] > bmi)

  const percentile = ['male', 'female'].includes(gender)
    ? percentileIndex === -1
      ? 100
      : bmiPercentiles[Math.max(0, percentileIndex - 1)].percentile
    : null

  return { bmi, status, percentile }
}

// this was moved to the backend (Nutrition#get_pregnancy_offset),
// but keeping here in case we need it on the frontend later
const getCalorieAdjustmentFromUserInfo = (info) => {
  if (info.gender === 'female' && info.pregnant === 'Yes') {
    if (!['One', 'Two', 'ThreeOrMore'].includes(info.number_of_babies)) {
      throw new Error('Invalid number_of_babies')
    }

    if (!_.isNumber(info.weeks_pregnant)) {
      throw new Error('Missing weeks_pregnant')
    }

    if (!_.isNumber(info.height_inches)) {
      throw new Error('Missing height_inches')
    }

    if (!_.isNumber(info.prepregnancy_weight_lbs)) {
      throw new Error('Missing prepregnancy_weight_lbs')
    }

    const prePregnancyBMI = getBMI('female', info.height_inches, info.prepregnancy_weight_lbs, false)
    const trimester = info.weeks_pregnant <= 13 ? 1 : info.weeks_pregnant <= 27 ? 2 : 3

    if (prePregnancyBMI < 25) {
      return {
        One: [0, 340, 452],
        Two: [300, 680, 900],
        ThreeOrMore: [450, 1020, 1350]
      }[info.number_of_babies][trimester - 1]
    }

    if (prePregnancyBMI >= 25) {
      return {
        One: [0, 200, 400],
        Two: [250, 600, 800],
        ThreeOrMore: [400, 900, 1100]
      }[info.number_of_babies][trimester - 1]
    }
  }

  if (info.gender === 'female' && info.pregnant === 'No') {
    if (info.breastfeeding === 'Exclusively') {
      return 400
    }

    if (info.breastfeeding === 'BreastfeedingAndFormulaFeeding') {
      return 200
    }
  }

  return 0
}

const getGeneralUserInfoFieldDisplayWIP = (info = {}, partner = {}) => {
  // Determine the effective pregnancy status, defaulting to 'No' if pregnancy selection is hidden
  const effectivePregnantStatus = info.pregnant //  partner?.hide_pregnancy_selection ? 'No' : info.pregnant || 'No'
  const effectiveBreastFeedingStatus = info.breastfeeding // partner?.hide_pregnancy_selection ? 'NotCurrently' : info.breastfeeding || 'NotCurrently'

  return (
    info.gender !== 'female' ||
    (info.gender === 'female' && effectivePregnantStatus === 'No' && info.breastfeeding === 'NotCurrently') ||
    (info.gender === 'female' &&
      effectivePregnantStatus === 'No' &&
      ['Exclusively', 'BreastfeedingAndFormulaFeeding'].includes(effectiveBreastFeedingStatus) &&
      !!info.baby_dob) ||
    (info.gender === 'female' &&
      effectivePregnantStatus === 'Yes' &&
      !!info.prenatal_vitamins &&
      !!info.weeks_pregnant &&
      !!info.number_of_babies &&
      (!!info.prepregnancy_weight_lbs || !!info.prepregnancy_weight_kg))
  );
};
const getGeneralUserInfoFieldDisplay = (info = {}, partner = {}) =>
  info.gender !== 'female' ||
  (info.gender === 'female' && info.pregnant === 'No' && info.breastfeeding === 'NotCurrently') ||
  (info.gender === 'female' &&
    info.pregnant === 'No' &&
    ['Exclusively', 'BreastfeedingAndFormulaFeeding'].includes(info.breastfeeding) &&
    !!info.baby_dob) ||
  (info.gender === 'female' &&
    info.pregnant === 'Yes' &&
    !!info.prenatal_vitamins &&
    !!info.weeks_pregnant &&
    !!info.number_of_babies &&
    (!!info.prepregnancy_weight_lbs || !!info.prepregnancy_weight_kg))

export const conditionalUserInfoFields = [
  'pregnant',
  'prenatal_vitamins',
  'weeks_pregnant',
  'number_of_babies',
  'prepregnancy_weight_lbs',
  'prepregnancy_weight_kg',
  'breastfeeding',
  'baby_dob',
  'high_bp',
  'high_bs',
  'hba1c',
  'systolic',
  'diastolic'
]

export const getUserInfoFields = (partner) => {
  return [
    {
      id: "gender",
      label: "Gender*",
      display: () => true,
      options: [
        { value: "male", label: "Male", icon: null },
        { value: "female", label: "Female", icon: null },
        { value: "non_binary", label: "Non Binary", icon: null },
        { value: "prefer_to_self_describe", label: "Prefer to self-describe", icon: null },
        { value: "prefer_not_to_answer", label: "Prefer not to answer", icon: null }
      ]
    },
    {
      id: "pregnant",
      label: "Are you pregnant?*",
      display: (info, partner) => info.gender === "female",// && getGeneralUserInfoFieldDisplay(info, partner), //- todo finish implementing hide_pregnancy_selection
      options: [
        { value: "No", label: "No" },
        { value: "Yes", label: "Yes" }
      ]
    },
    {
      id: "prenatal_vitamins",
      label: "Are you taking any pre-natal vitamins?*",
      display: (info) => info.gender === "female" && info.pregnant === "Yes",
      options: [
        { value: "No", label: "No" },
        { value: "Yes", label: "Yes" }
      ]
    },
    {
      id: "weeks_pregnant",
      label: "How far along?*",
      display: (info) => info.gender === "female" && info.pregnant === "Yes" && !!info.prenatal_vitamins,
      type: "number",
      min: 1,
      minError: "Must be 1 or more weeks",
      max: 42,
      maxError: "Must be 42 weeks or less"
    },
    {
      id: "number_of_babies",
      label: "How many?*",
      display: (info) =>
        info.gender === "female" && info.pregnant === "Yes" && !!info.prenatal_vitamins && !!info.weeks_pregnant,
      options: [
        { value: "One", label: "One baby" },
        { value: "Two", label: "Twins" },
        { value: "ThreeOrMore", label: "3 or more" }
      ]
    },
    {
      id: "prepregnancy_weight_lbs",
      label: "What was your pre-pregnancy weight?*",
      display: (info) =>
        info.gender === "female" &&
        info.pregnant === "Yes" &&
        !!info.prenatal_vitamins &&
        !!info.weeks_pregnant &&
        !!info.number_of_babies,
      type: "number",
      min: 20,
      minError: "Weight must be at least 20 pounds",
      max: 700,
      maxError: "Weight cannot exceed 700 pounds",
      metric: false
    },
    {
      id: "prepregnancy_weight_kg",
      label: "What was your pre-pregnancy weight?*",
      display: (info) =>
        info.gender === "female" &&
        info.pregnant === "Yes" &&
        !!info.prenatal_vitamins &&
        !!info.weeks_pregnant &&
        !!info.number_of_babies,
      type: "number",
      min: 10,
      minError: "Weight must be at least 10 kgs",
      max: 318,
      maxError: "Weight cannot exceed 318 kgs",
      metric: true
    },
    {
      id: "breastfeeding",
      label: "Breastfeeding?*",
      display: (info) => info.gender === "female" && info.pregnant === "No",
      options: [
        { value: "NotCurrently", label: "Not Currently Breastfeeding" },
        { value: "Exclusively", label: "Exclusively Breastfeeding" },
        { value: "BreastfeedingAndFormulaFeeding", label: "Breastfeeding and formula feeding" }
      ]
    },
    {
      id: "baby_dob",
      label: "Baby's Date of Birth*",
      display: (info) =>
        info.gender === "female" &&
        info.pregnant === "No" &&
        ["Exclusively", "BreastfeedingAndFormulaFeeding"].includes(info.breastfeeding),
      type: "date"
    },
    {
      id: "age_years",
      label: "Age*",
      display: (info) => !partner.collect_dob && getGeneralUserInfoFieldDisplay(info),
      //display: getGeneralUserInfoFieldDisplay,
      type: "number",
      min: 3,
      minError: "You must be 3 or older to use this service",
      max: 120,
      maxError: "Age cannot exceed 120"
    },
    {
      id: "weight_lbs",
      label: "Weight*",
      display: (info) => getGeneralUserInfoFieldDisplay(info, partner),
      type: "number",
      min: 20,
      minError: "Weight must be at least 20 pounds",
      max: 700,
      maxError: "Weight cannot exceed 700 pounds",
      metric: false
    },
    {
      id: "weight_kg",
      label: "Weight*",
      display: (info) => getGeneralUserInfoFieldDisplay(info, partner),
      type: "number",
      min: 10,
      minError: "Weight must be at least 10 kgs",
      max: 318,
      maxError: "Weight cannot exceed 318 kgs",
      metric: true
    },
    {
      id: "height_inches",
      label: "Height*",
      display: (info) => getGeneralUserInfoFieldDisplay(info, partner),
      type: "number",
      min: 2 * 12,
      minError: "Height must be at least 2 feet",
      max: 9 * 12,
      maxError: "Height can't be more than 9 feet",
      metric: false
    },
    {
      id: "height_cm",
      label: "Height*",
      display: (info) => getGeneralUserInfoFieldDisplay(info, partner),
      type: "number",
      min: 60,
      minError: "Height must be at least 60 cm",
      max: 250,
      maxError: "Height can't be more than 250 cm",
      metric: true
    },
    {
      id: "bmi",
      label: "BMI*",
      display: (info) => getGeneralUserInfoFieldDisplay(info, partner),
      type: "display"
    },
    {
      id: "weight_trend",
      label: "Your weight is*",
      display: (info) => getGeneralUserInfoFieldDisplay(info, partner),
      missingError: "Please select an option",
      options: [
        { value: "rising", label: "Rising", icon: "trending-up" },
        { value: "constant", label: "Constant", icon: "arrow-round-forward" },
        { value: "falling", label: "Falling", icon: "trending-down" }
      ]
    },
    {
      id: "activity_level",
      label: "Your normal activity level*",
      display: (info) => getGeneralUserInfoFieldDisplay(info, partner),
      options: [
        {
          value: "minimal",
          label: "Minimal",
          description: "Little to no exercise",
          extended_description: "Mainly sitting, some walking and daily activities like cooking, house chores, shopping, etc."
        },
        {
          value: "light",
          label: "Light",
          description: "Non-strenuous walking, cycling, etc. for 30 min. 1-2x/wk",
          extended_description: "Sustained light activity to slightly elevate heart rate, and/or light weight strength training for at least 30 minutes 1-2 times a week."
        },
        {
          value: "moderate",
          label: "Moderate",
          description: "Medium intensity walking, cycling etc. for 30-60 min. 3-4x/wk",
          extended_description: "Sustained medium intensity activity to slightly elevate heart rate, and/or light to moderate strength training for at 30-60 minutes 3-4 times a week."
        },
        {
          value: "active",
          label: "Active",
          description: "Vigorous exercise for at least 60 minutes 3-4x/wk",
          extended_description: "Sustained cardio activity to significantly elevate heart rate, and/or continuous intense strength training for at least 60 minutes 3-4 times a week."
        },
        {
          value: "intense",
          label: "Intense",
          description: "Intensive athletic training several hours/day",
          extended_description: "Daily training regimen for athletic competition or event."
        }
      ]
    },
    {
      id: "high_bp",
      label: "Are you taking medication for high blood pressure?",
      display: (info) => partner.collect_extended_user_info && getGeneralUserInfoFieldDisplay(info, partner),
      options: [
        { value: "No", label: "No" },
        { value: "Yes", label: "Yes" }
      ]
    },
    {
      id: "high_bs",
      label: "Are you taking medication for diabetes or high blood sugar?",
      display: (info) => partner.collect_extended_user_info && getGeneralUserInfoFieldDisplay(info, partner),
      options: [
        { value: "No", label: "No" },
        { value: "Yes", label: "Yes" }
      ]
    },
    {
      id: "hba1c",
      label: "What is your HbA1c (A1c)?",
      suffix: "mg/dL",
      display: (info) => partner.collect_extended_user_info && getGeneralUserInfoFieldDisplay(info, partner),
      type: "string"
    },
    {
      id: "systolic",
      label: "What is your systolic blood pressure?",
      display: (info) => partner.collect_extended_user_info && getGeneralUserInfoFieldDisplay(info, partner),
      type: "string"
    },
    {
      id: "diastolic",
      label: "What is your diastolic blood pressure?",
      display: (info) => partner.collect_extended_user_info && getGeneralUserInfoFieldDisplay(info, partner),
      type: "string"
    },
    {
      id: "custom_field",
      label: partner.custom_field || "Custom Field",
      display: (info) => getGeneralUserInfoFieldDisplay(info, partner),
      type: "string"
    }
  ]
    .map((field) => ({
      ...field,
      ...(field.label ? { label: t(field.label) } : {}),
      ...(field.minError ? { minError: t(field.minError) } : {}),
      ...(field.maxError ? { maxError: t(field.maxError) } : {}),
      ...(field.missingError ? { missingError: t(field.missingError) } : {}),
      ...(field.options
        ? {
          options: field.options.map((option) => ({
            ...option,
            ...(option.label ? { label: t(option.label) } : {}),
            ...(option.description ? { description: t(option.description) } : {})
          }))
        }
        : {})
    }))
    .filter(
      ({ id, metric }) =>
        (typeof metric === "undefined" || metric === partner.use_metric_system) &&
        (id !== "bmi" || partner.show_bmi) &&
        (id !== "custom_field" || (partner.custom_field || "").trim().length)
    )
}

export const getDietRestrictionsForScreener = (screener) =>
  [
    [{ fish: 'no' }, 'ShellfishFree'],
    [{ grains: 'no' }, 'WheatFree'],
    [{ dairy: 'no' }, 'DairyFree']
  ]
    .filter(([filter]) => Object.keys(filter).every((foodType) => screener[foodType] === filter[foodType]))
    .map(([_, restriction]) => restriction)

const getDietTypeForScreener = (screener) =>
  [
    [{ meat: 'yes', poultry: null, fish: null, grains: 'yes', dairy: null, style: 'LOC' }, 'LOC'],
    [{ meat: 'yes', poultry: null, fish: null, grains: 'yes', dairy: null, style: 'LOF' }, 'LOF'],
    [{ meat: 'yes', poultry: null, fish: null, grains: 'yes', dairy: null, style: 'MED' }, 'MED'],
    [{ meat: 'yes', poultry: null, fish: null, grains: 'yes', dairy: null, style: 'MEX' }, 'MEX'],
    [{ meat: 'yes', poultry: null, fish: null, grains: 'yes', dairy: null, style: 'VTM' }, 'VTM'],
    [{ meat: 'yes', poultry: null, fish: null, grains: 'yes', dairy: null, style: 'KOR' }, 'KOR'],
    [{ meat: 'yes', poultry: null, fish: null, grains: 'yes', dairy: null, style: 'CAR_WI' }, 'CAR_WI'],
    [{ meat: 'yes', poultry: null, fish: null, grains: 'yes', dairy: null, style: 'SOU' }, 'SOU'],
    [{ meat: 'yes', poultry: null, fish: null, grains: 'yes', dairy: null, style: 'CEN' }, 'CEN'],
    [{ meat: 'yes', poultry: null, fish: null, grains: 'yes', dairy: null, style: 'CES' }, 'CES'],
    [{ meat: 'yes', poultry: null, fish: null, grains: 'yes', dairy: null, style: 'SA' }, 'SA'],
    [{ meat: 'yes', poultry: null, fish: null, grains: 'no', dairy: 'yes', style: 'LOC' }, 'LOC'],
    [{ meat: 'yes', poultry: null, fish: null, grains: 'no', dairy: 'yes', style: 'KET' }, 'KET'],
    [{ meat: 'yes', poultry: null, fish: null, grains: 'yes', dairy: null }, 'AME'],
    [{ meat: 'yes', poultry: null, fish: null, grains: 'no', dairy: 'yes' }, 'LOC'],
    [{ meat: 'yes', poultry: null, fish: null, grains: 'no', dairy: 'no' }, 'PAL'],
    [{ meat: 'sometimes', poultry: null, fish: null, grains: 'yes', dairy: null, style: 'MEX' }, 'MEX'],
    [{ meat: 'sometimes', poultry: null, fish: null, grains: 'yes', dairy: null, style: 'FLX' }, 'FLX'],
    [{ meat: 'sometimes', poultry: null, fish: null, grains: 'yes', dairy: null, style: 'MED' }, 'MED'],
    [{ meat: 'sometimes', poultry: null, fish: null, grains: 'yes', dairy: null, style: 'SA' }, 'SA'],
    [{ meat: 'sometimes', poultry: null, fish: null, grains: 'yes', dairy: null, style: 'VTM' }, 'VTM'],
    [{ meat: 'sometimes', poultry: null, fish: null, grains: 'yes', dairy: null, style: 'KOR' }, 'KOR'],
    [{ meat: 'sometimes', poultry: null, fish: null, grains: 'yes', dairy: null, style: 'CAR_WI' }, 'CAR_WI'],
    [{ meat: 'sometimes', poultry: null, fish: null, grains: 'yes', dairy: null, style: 'LOF' }, 'LOF'],
    [{ meat: 'sometimes', poultry: null, fish: null, grains: 'yes', dairy: null }, 'FLX'],
    [{ meat: 'sometimes', poultry: null, fish: null, grains: 'no', dairy: 'no' }, 'PAL'],
    [{ meat: 'sometimes', poultry: null, fish: null, grains: 'no', dairy: 'yes' }, 'LOC'],
    [{ meat: 'no', poultry: 'no', fish: 'no', grains: null, dairy: 'yes', style: 'VET' }, 'VET'],
    [{ meat: 'no', poultry: 'no', fish: 'no', grains: null, dairy: 'yes', style: 'SA_V' }, 'SA_V'],
    [{ meat: 'no', poultry: 'no', fish: 'no', grains: null, dairy: 'yes', style: 'MEX_V' }, 'MEX_V'],
    [{ meat: 'no', poultry: 'yes', fish: null, grains: null, dairy: null }, 'NRM'],
    [{ meat: 'no', poultry: 'no', fish: 'yes', grains: null, dairy: null }, 'PES'],
    [{ meat: 'no', poultry: 'no', fish: 'no', grains: null, dairy: 'yes' }, 'VET'],
    [{ meat: 'no', poultry: 'no', fish: 'no', grains: null, dairy: 'no' }, 'VEG']
  ].find(([filter]) => Object.keys(filter).every((foodType) => screener[foodType] === filter[foodType]))[1]

const getPinchingPaths = (lowerBound, upperBound, priorChoice, level = 0) => {
  const adjascent = priorChoice && (priorChoice - lowerBound <= 2 || upperBound - priorChoice <= 2)

  let lowerChoice = Math.max(
    0,
    lowerBound,
    adjascent ? priorChoice - 1 : Math.ceil(lowerBound + (upperBound - lowerBound) * (priorChoice ? 1 / 4 : 1 / 3))
  )
  let upperChoice = Math.max(
    0,
    Math.min(
      upperBound,
      adjascent ? priorChoice + 1 : Math.ceil(lowerBound + (upperBound - lowerBound) * (priorChoice ? 3 / 4 : 2 / 3))
    )
  )

  // handle pinching between 2 diets
  if (lowerChoice === upperChoice && level === 0 && lowerBound + 1 === upperBound) {
    lowerChoice = lowerBound
    upperChoice = upperBound
  }

  if (lowerChoice === upperChoice) {
    return typeof priorChoice === 'undefined' ? [] : [[priorChoice]]
  }

  // console.log(new Array(level).join('    ') + `${priorChoice || ''} -> ${lowerChoice} or ${upperChoice} (bounds: ${lowerBound}-${upperBound})`)

  const lowerPaths = getPinchingPaths(lowerBound, upperChoice - 1, lowerChoice, level + 1).map((path) => [
    priorChoice,
    ...path
  ])
  const upperPaths = getPinchingPaths(lowerChoice + 1, upperBound, upperChoice, level + 1).map((path) => [
    priorChoice,
    ...path
  ])

  return [
    ...(level ? lowerPaths : lowerPaths.map((path) => path.slice(1))),
    ...(level ? upperPaths : upperPaths.map((path) => path.slice(1)))
  ]
}

const getCellXY = (matrix, cell) => {
  const y = matrix.rows.findIndex((row) => row.includes(cell))
  const x = matrix.rows[y].indexOf(cell)
  return { x, y }
}

const getConfirmedPaths = (paths) =>
  paths.reduce(
    (confirmPaths, cell, i, paths) => [
      ...confirmPaths,
      i === paths.length - 1 ? paths : [...paths.slice(0, i + 1), paths[i]]
    ],
    []
  )

const getColPaths = (matrix, anchorCell, maxDistance = 2) => {
  const { x } = getCellXY(matrix, anchorCell)

  const cells = _.compact(matrix.rows.map((row) => row[x]))
  const cellIndex = cells.indexOf(anchorCell)

  const northCells = cells.slice(Math.max(cellIndex - maxDistance, 0), cellIndex + 1).reverse()
  const southCells = cells.slice(cellIndex, Math.min(cellIndex + 1 + maxDistance, cells.length))

  let northPaths = getConfirmedPaths(northCells)
  let southPaths = getConfirmedPaths(southCells)

  // check immediately north (y+1) cell first. if cell (y) is confirmed, abandon north (y+n) path and check south (y-n) path
  southPaths = southPaths.map((path) => [path[0], ...path])

  return [...northPaths, ...southPaths].filter((path) => path.length > 1)
}

const getRowPaths = (matrix, anchorCell) => {
  const { y } = getCellXY(matrix, anchorCell)

  const cells = _.compact(matrix.rows[y])
  const cellIndex = cells.indexOf(anchorCell)

  const westCells = cells.slice(0, cellIndex + 1).reverse()
  const eastCells = cells.slice(cellIndex)

  let westPaths = getConfirmedPaths(westCells)
  let eastPaths = getConfirmedPaths(eastCells)

  // check immediately east (x+1) cell first. if cell (x) is confirmed, abandon east (x+n) path and check west (x-n) path
  westPaths = westPaths.map((path) => [path[0], ...path])

  return [...eastPaths, ...westPaths].filter((path) => path.length > 1)
}

const getVerificationPaths = (matrix, anchorCell) => {
  return getRowPaths(matrix, anchorCell).reduce((fullPaths, rowPath) => {
    const lastCell = rowPath[rowPath.length - 1]

    // don't verify the anchor cell
    const colPaths = lastCell === anchorCell ? [] : getColPaths(matrix, lastCell)

    const fullPath = colPaths.length ? colPaths.map((colPath) => [...rowPath, ...colPath.slice(1)]) : [rowPath]

    return [...fullPaths, ...fullPath]
  }, [])
}

const getPinchingInfo = (matrix, selections, dietType, maxY = Infinity) => {
  const columnIndex = matrix.columns.indexOf(dietType) // so if therapuetic this does not find a column
  const columnDiets = _.compact(matrix.rows.map((row, i) => (i <= maxY ? row[columnIndex] : null)))
  const lastSelection = selections.length ? selections[selections.length - 1] : null

  /*console.log('matrix ', matrix)
  console.log('selections ', selections)
  console.log('getDietTypeForScreener dietType', dietType)
  console.log('lastSelection parent ', lastSelection)
  console.log('columnIndex ', columnIndex)
  console.log('columnDiets.length ', columnDiets.length)*/

  const allPaths = getPinchingPaths(0, columnDiets.length - 1)
  const validPaths = allPaths.filter((path) =>
    path.every((dietIndex, i) => !selections[i] || selections[i].id === columnDiets[dietIndex].id)
  )
  const options = _.uniq(_.compact(validPaths.map((path) => columnDiets[path[selections.length]])))

  /*  console.log('allPaths ', allPaths)
    console.log('validPaths ', validPaths)
    console.log('options ', options)*/
  // skip past decisions with only 1 option
  if (options.length === 1) {
    return getPinchingInfo(matrix, [...selections, options[0]], dietType, maxY)
  }

  let maxSteps = Math.max(...validPaths.map((path) => path.length))

  // if there's only 1 diet, that's our diet
  if (!options.length && columnDiets.length === 1) {
    selections = columnDiets
    maxSteps = 1
  }

  const progress = {
    percentComplete: Math.min(1, selections.length / maxSteps),
    completedSteps: selections.length,
    totalSteps: maxSteps
  }

  return { selections, options, progress, maxSteps }
}

const getVerificationInfo = (matrix, selections) => {
  if (!selections.length) {
    return null
  }

  const allPaths = getVerificationPaths(matrix, getDiet(matrix, selections[0].id).diet)
  const validPaths = allPaths.filter((path) => path.every((diet, i) => !selections[i] || selections[i].id === diet.id))
  const options = _.uniq(_.compact(validPaths.map((path) => path[selections.length])))

  // console.log('getVerificationInfo ', 'selections', 'allPaths', 'validPaths', 'options')
  // console.log('getVerificationInfo ', selections, allPaths, validPaths, options)

  // skip past decisions with only 1 option
  if (options.length === 1) {
    return getVerificationInfo(matrix, [...selections, options[0]])
  }

  const maxSteps = Math.max(...validPaths.map((path) => path.length - 1))

  const progress = {
    percentComplete: (selections.length - 1) / maxSteps,
    completedSteps: selections.length,
    totalSteps: maxSteps
  }

  return { selections, options, progress }
}

export const getDiet = (matrix, id) => {
  let info = {}

  matrix.rows.forEach((row, y) => {
    row.forEach((cell, x) => {
      if ((cell || {}).id === id) {
        info = { diet: cell, xy: [x, y] }
      }
    })
  })

  return info
}

export const getDietType = (matrix, dietId) => matrix.columns[getDiet(matrix, dietId).xy[0]]

export const getIdSelectionInfo = (matrix, selections, screener, isTherapeutic, partner) => {
  const lastSelection = selections.length ? selections[selections.length - 1] : null

  const pinching = getPinchingInfo(matrix, selections, lastSelection?.code || getDietTypeForScreener(screener))
  // console.log('pinching ', pinching)

  let pinchingSelections = pinching.selections.slice(0, pinching.maxSteps - 1)
  let verificationSelections = pinching.selections.slice(pinching.maxSteps - 1)

  if (verificationSelections.length) {
    const verification = getVerificationInfo(matrix, verificationSelections)

    const originalCompletedSteps = pinching.progress.completedSteps
    const totalSteps = isTherapeutic ? 5 : pinching.progress.totalSteps + verification.progress.totalSteps
    const completedSteps = [...pinchingSelections, ...verification.selections].length
    const percentComplete = Math.min(1, completedSteps / totalSteps)

    /*  console.log('originalCompletedSteps ', originalCompletedSteps)
      console.log('totalSteps ', totalSteps)
      console.log('completedSteps ', completedSteps)
      console.log('percentComplete ', percentComplete)*/

    const suppressingHealthGoals = partner.suppress_healthgoals

    return {
      selections: [...pinchingSelections, ...verification.selections],
      options: isTherapeutic
        ? completedSteps > 4
          ? []
          : [lastSelection]
        : [...pinching.options, ...verification.options],
      progress: {
        percentComplete: isTherapeutic && completedSteps > 4 ? 1 : percentComplete,
        completedSteps: completedSteps,
        totalSteps: totalSteps
      }
    }
  }

  // estimating a max of 6 verification steps
  const totalSteps = pinching.progress.totalSteps + 6
  const completedSteps = pinching.progress.completedSteps
  const percentComplete = Math.min(1, completedSteps / totalSteps)

  return {
    selections: pinching.selections,
    options: pinching.options,
    progress: {
      percentComplete,
      completedSteps,
      totalSteps
    }
  }
}

export const getIdealSelectionInfo = (matrix, selections, dietType, idDietId, healthGoals) => {
  const [idColumnIndex, idRowIndex] = getDiet(matrix, idDietId).xy
  const managingHeartFailure = healthGoals.includes('manage_heart_failure')
  let lowerBound = idRowIndex

  const idealIsEastOfId = matrix.columns.indexOf(dietType) > idColumnIndex

  // if ideal diet is [west or equal to the id diet] or [east of the id diet and the id diet quality < 9], it must be of higher quality
  if (!idealIsEastOfId || (idealIsEastOfId && idRowIndex > 10 - 9)) {
    lowerBound = Math.max(0, idRowIndex - 1)
  }

  // ideal diet must be Q8+
  lowerBound = Math.min(lowerBound, 10 - 8)

  // ideal diet must be Q10 if managing heart failure
  if (managingHeartFailure) {
    lowerBound = 0
  }

  const pinching = getPinchingInfo(matrix, selections, dietType, lowerBound)
  return _.pick(pinching, ['selections', 'options', 'progress'])
}

/*
const matrix = [
  ['a9', 'b9', 'c9', 'd9', 'e9', 'f9', 'g9', 'h9'],
  ['a8', null, 'c8', 'd8', 'e8', 'f8', 'g8', 'h8'],
  ['a7', null, 'c7', 'd7', 'e7', 'f7', 'g7', 'h7'],
  ['a6', null, 'c6', 'd6', 'e6', 'f6', 'g6', 'h6'],
  ['a5', null, 'c5', 'd5', 'e5', 'f5', 'g5', 'h5'],
  ['a4', 'b4', 'c4', 'd4', 'e4', 'f4', 'g4', 'h4'],
  ['a3', 'b3', 'c3', 'd3', 'e3', 'f3', 'g3', 'h3'],
  ['a2', 'b2', 'c2', 'd2', 'e2', 'f2', 'g2', 'h2'],
  ['a1', 'b1', 'c1', 'd1', 'e1', 'f1', 'g1', 'h1'],
  ['a0', 'b0', 'c0', 'd0', 'e0', 'f0', 'g0', 'h0'],
]

const paths = getVerificationPaths(matrix, 'd4')
paths.filter(path => path[3] === 'a4').forEach(path => console.log(path.join(' -> ')))
*/
