import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import breaks from 'remark-breaks'
import ReactMarkdown from 'react-markdown'
import { Device } from '@capacitor/device'
import _ from 'lodash'
import capitalize from 'lodash.capitalize'
import Button from 'app/components/Button'
import colors from 'app/lib/colors'
import { getUrlParam } from 'app/lib/util'
import t from 'app/lib/i18n'
import * as constants from 'app/lib/constants'
import * as actions from 'app/actions/auth'
import { ackLandingPage } from 'app/actions/general'
import { Checkbox } from '@blueprintjs/core'
import {
    AUTH_SCREEN_ENTER_OTP,
    AUTH_SCREEN_PARENTAL_CONSENT, AUTH_SCREEN_PARENTAL_CONSENT_UNCHECKED,
    AUTH_SCREEN_TOS,
    AUTH_SCREEN_TOS_UNCHECKED
} from "../actions/auth";

const padding = 25
const width = Math.min(350, window.innerWidth - padding * 2)

const Wrap = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-content: stretch;
  justify-content: stretch;
  color: ${colors.darkGray};

  .sidebar {
    display: none;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.primary};
    background: ${props =>
      `linear-gradient(to right, ${colors.hexToRgb(props.theme.primary, 0.8)} 0%, ${colors.hexToRgb(
        props.theme.primary,
        1
      )} 100%)`};
    background-size: cover;
    background-position: center;
    text-align: center;
    color: ${colors.white};

    h1 {
      font-size: 68px;
      margin: 10px 50px;
    }

    .text {
      font-size: 28px;
      line-height: 1.29;
      margin: 50px auto;
      padding: 0 50px;
      max-width: 580px;

      a {
        color: ${colors.white};
        text-decoration: underline;
      }
    }
  }

  .fixedWelcome {
    color: ${colors.white};
    background-color: ${props => props.theme.primary};
    max-height: 150px;
    padding: 10px;
  }

  .content {
    flex: 1;
    background-color: ${colors.white};
  }

  a {
    color: ${props => props.theme.primary};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .legal-wrap {
    margin-bottom: 20px;
  }

  /* Make it work on desktop, with full size sidebar and scrollable content area */
  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    height: 100%;
    overflow: visible;

    .main {
      height: 100%;
      overflow: visible;
    }

    .sidebar {
      display: flex;
    }

    .content {
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }

    .fixedWelcome {
      display: none;
    }
  }
`

const MainWrap = styled.div`
  text-align: center;
`

const Logo = styled.img`
  margin: 25px;
  max-width: 60%;
  height: auto;
`

const PoweredBy = styled.div`
  font-size: 14px;
  color: ${props => props.theme.black};
  text-align: center;
`

const SignInCTAWrap = styled.div`
  margin-bottom: 40px;
`

const Attribution = styled.div`
  padding: 30px 0 40px;
  text-align: center;
  color: ${colors.darkGray};
`

const Heading = styled.div`
  font-size: 22px;
  font-weight: bold;

  &.top-heading {
    margin-bottom: -20px;
  }
`

const AuthButton = styled(Button)`
  display: block;
  max-width: 60%;
  &.footer-button {
    margin-top: -20px;
  }

  &.forgot-password-link {
    margin-top: -10px;
  }
`

const FormWrap = styled.div`
  margin: 40px 0 20px;
  text-align: center;
`

const InputWrap = styled.div`
  margin: 10px 0;
`

const StyledInput = styled.input`
  box-sizing: border-box;
  padding: 15px 10px;
  border: 1px ${colors.lightGray} solid;
  font-size: 18px;
  text-align: center;
  max-width: 80%;
  width: 100%;
`

const Input = ({ placeholder, ...props }) => (
  <StyledInput {...props} {...(placeholder ? { placeholder: t(placeholder) } : {})} />
)

const Separator = styled.div`
  max-width: 80%;
  height: 1px;
  background-color: ${colors.lightGray2};
  margin: 10px auto 17px;
`

const FormMessage = styled.div`
  box-sizing: border-box;
  padding: 15px 10px;
  margin: 10px auto;
  border: 1px solid transparent;
  font-size: 18px;
  text-align: center;
  max-width: 80%;
  color: ${colors.darkGray};
`

const FormError = styled.div`
  color: ${colors.red};
  max-width: 80%;
  margin: 3px auto;
  font-size: 12px;
`

const FieldMessage = styled.div`
  max-width: 80%;
  margin: 3px auto 13px;
  font-size: 12px;
  color: ${colors.darkGray};
`

const Authentication = props => {
  const { partner, auth } = props

  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [dob, setDob] = useState('')
  const [appCode, setAppCode] = useState('')
  const [accessCode, setAccessCode] = useState(getUrlParam('studycode'))
  const [autoLogin, setAutoLogin] = useState(Boolean(getUrlParam('studycode')))
  const [showAccessCodeRecovery, setShowAccessCodeRecovery] = useState(false)
  const [otp, setOtp] = useState('')
  const [deviceInfo, setDeviceInfo] = useState({})

  // keep the resetEmail separate from email since it's set in the url (for security [phishing] reasons)
  const [resetEmail] = useState(getUrlParam('email'))
  const [resetToken, setResetToken] = useState(getUrlParam('reset_token'))

  const nbty = partner.code === 'nbty'
  let useAppCodeForm = ['ios', 'android'].includes(deviceInfo.platform)
  let {
    code_based_login_active: useAccessCodeForm,
    sign_up_enabled: signupEnabled,
    virgin_pulse_login: virginPulseLogin,
    epic_login: epicLogin,
    id: partnerId
  } = partner
  let view =
    {
      [actions.AUTH_SCREEN_SIGN_UP]: 'SignUp',
      [actions.AUTH_SCREEN_RESET_PASSWORD]: 'ResetPassword',
      [actions.AUTH_SCREEN_RESET_PASSWORD_DONE]: 'ResetPasswordDone',
      [actions.AUTH_SCREEN_UPDATE_PASSWORD]: 'UpdatePassword',
      [actions.AUTH_SCREEN_UPDATE_PASSWORD_DONE]: 'UpdatePasswordDone'
    }[resetToken ? actions.AUTH_SCREEN_UPDATE_PASSWORD : auth.screen] || 'SignIn'

  useEffect(() => {
    ;(async () => {
      setDeviceInfo(await Device.getInfo())
    })()
  }, [])

  if (view !== "ResetPassword" && (useAppCodeForm || virginPulseLogin) && !epicLogin) {
    signupEnabled = false
    view = 'SignIn'
  }
  if (view !== "ResetPassword" && view !== 'UpdatePassword' && view !== 'UpdatePasswordDone' && view !== "SignUp" && epicLogin) {
    view = 'SignIn'
  }
  let HUNDRED_YEARS_AGO = new Date()
  HUNDRED_YEARS_AGO.setFullYear(new Date().getFullYear() - 120)
  HUNDRED_YEARS_AGO = HUNDRED_YEARS_AGO.toISOString().split('T')[0]
  let THIRTEEN_YEARS_AGO = new Date()
  THIRTEEN_YEARS_AGO.setFullYear(new Date().getFullYear() - 13)
  THIRTEEN_YEARS_AGO = THIRTEEN_YEARS_AGO.toISOString().split('T')[0]
  let THREE_YEARS_AGO = new Date()
  THREE_YEARS_AGO.setFullYear(new Date().getFullYear() - 3)
  THREE_YEARS_AGO = THREE_YEARS_AGO.toISOString().split('T')[0]

  const renderError = (field, prefix = true) => {
    const errors = ({
      SignIn: auth.signInErrors,
      SignUp: auth.signUpErrors,
      ResetPassword: auth.resetPasswordErrors,
      UpdatePassword: auth.updatePasswordErrors
    }[view] || {})[field]

    if (errors) {
      const errorMsg = errors
        .map(err => {
          const fieldName = field
            .split('_')
            .map(w => capitalize(w))
            .join(' ')
          return (prefix ? fieldName + ' ' : '') + err
        })
        .join(', ')

      return <FormError>{t(errorMsg)}</FormError>
    }
  }

  const showSignIn = () => props.dispatch(actions.changeScreen(actions.AUTH_SCREEN_SIGN_IN))
  const showSignUp = () => props.dispatch(actions.changeScreen(actions.AUTH_SCREEN_SIGN_UP))
  const showPasswordReset = () => props.dispatch(actions.changeScreen(actions.AUTH_SCREEN_RESET_PASSWORD))

  const onSignInButtonClick = () => props.dispatch(actions.signIn(email, password, accessCode, otp, appCode, phone))
  const onVirginPulseSignInButtonClick = () => props.dispatch(actions.signInVirginPulse(partnerId))
  const onEpicSignInButtonClick = () => props.dispatch(actions.signInEpic(partnerId))

  useEffect(() => {
    if (accessCode && autoLogin) {
      onSignInButtonClick()
    }
  }, [autoLogin, accessCode])
  const onSignUpButtonClick = () => {
    props.dispatch(
      actions.signUp({
        email: phone ? null : email,
        username,
        password,
        password_confirmation: passwordConfirmation,
        first_name: firstName,
        last_name: lastName,
        postal_code: postalCode,
        dob: dob,
        otp: otp,
        parental_consent_given: auth.parentalConsentGiven,
        service_id: auth.serviceId,
        phone_number: phone ? '+1' + phone : null
      })
    )
  }

  const onResetPasswordClick = () => props.dispatch(actions.resetPassword(email))
  const onUpdatePasswordClick = () =>
    props.dispatch(actions.updatePassword(resetToken, resetEmail, password, passwordConfirmation))
  const toggleTOS = () => props.dispatch({ type: !auth.tos ? AUTH_SCREEN_TOS : AUTH_SCREEN_TOS_UNCHECKED })
  const toggleParentalConsent = () => props.dispatch({ type: !auth.parentalConsentGiven ? AUTH_SCREEN_PARENTAL_CONSENT : AUTH_SCREEN_PARENTAL_CONSENT_UNCHECKED }) //  todo finish

  const clearResetToken = () => {
    setResetToken(null)
    props.dispatch(ackLandingPage())
    window.history.replaceState({}, document.title, '/')
    showSignIn()
  }

  if (resetToken && auth.screen === actions.AUTH_SCREEN_UPDATE_PASSWORD_DONE) {
    clearResetToken()
  }

  let config = {
    showSignInCTABelow: false,
    signInHeading: 'Log in to your account',
    signUpHeading: 'Register to get started',
    signUpHeadingOTP: 'Enter Your Code:',
    resetPasswordHeading: 'Reset your password',
    resetPasswordDoneHeading: 'Reset your password',
    updatePasswordHeading: 'Update your password',
    updatePasswordDoneHeading: 'Update your password',
    signUpButtonText: 'Create my Profile'
  }

  if (partner && partner.code === 'd2cfreetrial') {
    config = {
      ...config,
      showSignInCTABelow: true,
      signUpHeading: 'Register to get started',
      signUpButtonText: "Let's Go"
    }
  }

  if (nbty) {
    config.showSignInCTABelow = true
    config.signUpHeading = ''
  }

  config = Object.entries(config).reduce(
    (c, [key, value]) => ({ ...c, [key]: _.isString(value) ? t(value) : value }),
    {}
  )

  const heading = {
    SignIn: config.signInHeading,
    SignUp: auth.enterOTP ? config.signUpHeadingOTP : config.signUpHeading,
    ResetPassword: config.resetPasswordHeading,
    ResetPasswordDone: config.resetPasswordDoneHeading,
    UpdatePassword: config.updatePasswordHeading,
    UpdatePasswordDone: config.updatePasswordDoneHeading
  }[view]

  const otpForm = (
    <>
      <FormMessage>
        {t(
          "Please check your phone or email for your 6-digit OTP. Be sure to check your spam folder if you don't immediately see an email from help@dietid.com"
        )}
      </FormMessage>
      <InputWrap>
        <Input type="text" placeholder="6-digit OTP" value={otp} onChange={e => setOtp(e.target.value)} />
        {renderError('otp')}
      </InputWrap>
    </>
  )

  const formattedAppCode = useMemo(
    () =>
      appCode
        .replace(/[^\d]/g, '')
        .replace(/(.{4})/g, '$1-')
        .replace(/-$/, '')
        .slice(0, 16 + 3),
    [appCode]
  )
  const isOverAge = (date, minAge = 13) => {
    // Return false immediately if the date is falsy
    if (!date) return false
    const inputDate = new Date(date)
    if (isNaN(inputDate)) return false // Check if the date is valid
    // Check if the year is before a reasonable lower bound (e.g., 1900)
    const year = inputDate.getFullYear()
    if (year < 1900) return false
    const currentDate = new Date()
    currentDate.setFullYear(currentDate.getFullYear() - minAge)
    return inputDate <= currentDate
  }
  const isChildAge = (date, minAge = 3) => {
    // Return false immediately if the date is falsy
    if (!date) return false
    const inputDate = new Date(date)
    if (isNaN(inputDate)) return false // Check if the date is valid
    // Check if the year is before a reasonable lower bound (e.g., 1900)
    const year = inputDate.getFullYear()
    if (year < 1900) return false
    const currentDate = new Date()
    currentDate.setFullYear(currentDate.getFullYear() - minAge)
    return inputDate <= currentDate
  }

  const forms = {
    appCodeSignIn: (
      <>
        <InputWrap>
          <Input
            type="text"
            placeholder="Enter Your Code"
            value={formattedAppCode}
            onChange={e => setAppCode(e.target.value)}
          />
          {renderError('app_code')}
        </InputWrap>
        <AuthButton onClick={onSignInButtonClick} loading={auth.signingIn} disabled={auth.signingIn} big>
          {t('Log In')}
        </AuthButton>
      </>
    ),

    accessCodeSignIn: (
      <>
        <InputWrap>
          <Input
            type="text"
            placeholder="Enter Your Access Code"
            value={accessCode}
            onChange={e => setAccessCode(e.target.value)}
          />
          {renderError('access_code')}
        </InputWrap>
        {showAccessCodeRecovery && (
          <FieldMessage>
            If you misplaced your activation code, please contact your organization administrator
            {partner.lead_email && (
              <>
                {' '}
                at{' '}
                <a href={`mailto:${partner.lead_email}?subject=${encodeURIComponent('Forgot Access Code')}`}>
                  {partner.lead_email}
                </a>
              </>
            )}
          </FieldMessage>
        )}
        {!showAccessCodeRecovery && (
          <AuthButton className="forgot-password-link" onClick={() => setShowAccessCodeRecovery(true)} link>
            {t('forgot access code?')}
          </AuthButton>
        )}
        {auth.enterOTP && otpForm}
        <AuthButton onClick={onSignInButtonClick} loading={auth.signingIn} disabled={auth.signingIn} big>
          {t('Log In')}
        </AuthButton>
      </>
    ),

    emailSignIn: (
      <>
        {!auth.noEmail && !auth.enterOTP && (
          <>
            {!partner.sign_up_enabled_hide_email && (
              <>
                <InputWrap>
                  <Input
                    type="email"
                    placeholder="Email"
                    autoCompleteType={false}
                    autocomplete={false}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    disabled={phone}
                  />
                  {renderError('email')}
                </InputWrap>
                <InputWrap>
                  <Input
                    type="password"
                    placeholder="Password"
                    autoCompleteType={false}
                    autocomplete={false}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    disabled={phone}
                  />
                  {renderError('password')}
                </InputWrap>
              </>
            )}

            {!(partner.sign_up_enabled_hide_email || partner.sign_up_enabled_hide_phone) && !auth.enterOTP && (
              <FormMessage>{t('or')}</FormMessage>
            )}
            {!partner.sign_up_enabled_hide_phone && (
              <InputWrap>
                <Input
                  type="tel"
                  placeholder={t("Cell phone number")}
                  autoCompleteType={false}
                  autocomplete={false}
                  value={phone}
                  onChange={e => setPhone(e.target.value.replace(/\D/g, ''))}
                  disabled={email}
                  maxLength={10}
                />
                {renderError('phone')}
              </InputWrap>
            )}
          </>
        )}
        {auth.enterOTP && otpForm}
        <br />
        <AuthButton
          onClick={onSignInButtonClick}
          loading={auth.signingIn}
          big
          disabled={
            auth.signingIn || (!phone && !email) || (!phone && password.length < 6) || (!email && phone.length != 10)
          }
        >
          {t('Log In')}
        </AuthButton>
        <br />
        {!partner.sign_up_enabled_hide_email && (
          <AuthButton className="forgot-password-link" onClick={showPasswordReset} link>
            {t('forgot password?')}
          </AuthButton>
        )}
      </>
    ),

    virginPulseSignIn: (
      <>
        <AuthButton
          onClick={onVirginPulseSignInButtonClick}
          loading={auth.signingIn}
          disabled={!auth.tos || auth.signingIn}
          big
        >
          {t('Log In via Virgin Pulse')}
        </AuthButton>
        <br />
        <Checkbox checked={auth.tos} style={{ display: 'inline', marginTop: 5 }} onChange={toggleTOS} />
        <a href={'https://www.dietid.com/terms-of-service'} target={'_blank'} rel="noreferrer">
          {t("By continuing, you agree to Diet ID's Terms of service")}
        </a>
      </>
    ),
    epicSignIn: (
      <>
        <AuthButton
          onClick={onEpicSignInButtonClick}
          loading={auth.signingIn}
          disabled={!auth.tos || auth.signingIn}
          big
        >
          {t('Get Started with MyChart')}
        </AuthButton>
        <br />
        <Checkbox checked={auth.tos} style={{ display: 'inline', marginTop: 5 }} onChange={toggleTOS} />
        <a href={'https://www.dietid.com/terms-of-service'} target={'_blank'} rel="noreferrer">
          {t("By continuing, you agree to Diet ID's Terms of service")}
        </a>
      </>
    ),

    signUp: (
      <>
        {auth.enterOTP && otpForm}
        {auth.enterOTP && (
          <>
            <InputWrap>
              <Input
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
              {renderError('first_name')}
            </InputWrap>
            <InputWrap>
              <Input type="text" placeholder="Last Name" value={lastName} onChange={e => setLastName(e.target.value)} />
              {renderError('last_name')}
            </InputWrap>
            {partner.navigation_active && (
              <InputWrap>
                <Input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={e => setUsername(e.target.value.replace(/[^a-zA-Z0-9_]/g, ''))}
                />
                {renderError('username')}
              </InputWrap>
            )}
            <AuthButton onClick={onSignUpButtonClick} loading={auth.signingIn} disabled={auth.signingIn} big>
              {t('Log In')}
            </AuthButton>
          </>
        )}
        {!auth.enterOTP && (
          <>
            {!partner.sign_up_enabled_hide_phone && (
              <InputWrap style={{ marginTop: 20 }}>
                <Input
                  type="tel"
                  placeholder={t("Cell phone number")}
                  autoComplete={false}
                  autoCompleteType={false}
                  autocomplete={false}
                  value={phone}
                  onChange={e => setPhone(e.target.value.replace(/\D/g, ''))}
                  maxLength={10}
                  disabled={email}
                />
                {renderError('phone')}
              </InputWrap>
            )}
            {!(partner.sign_up_enabled_hide_email || partner.sign_up_enabled_hide_phone) && (
              <FormMessage>{t('or')}</FormMessage>
            )}
            {!partner.sign_up_enabled_hide_email && (
              <>
                <InputWrap>
                  <Input
                    type="email"
                    placeholder="Email"
                    autoCompleteType={false}
                    autocomplete={false}
                    autoComplete={false}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    disabled={phone}
                  />
                  {phone.length ? '' : renderError('email')}
                </InputWrap>
                <InputWrap>
                  <Input
                    type="password"
                    placeholder="Password"
                    autoCompleteType={false}
                    autocomplete={false}
                    autoComplete={false}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    disabled={phone}
                  />
                  {phone.length ? '' : renderError('password')}
                </InputWrap>
                <InputWrap>
                  <Input
                    type="password"
                    placeholder="Confirm Password"
                    value={passwordConfirmation}
                    onChange={e => setPasswordConfirmation(e.target.value)}
                    disabled={phone}
                  />
                  {phone.length ? '' : renderError('password_confirmation')}
                </InputWrap>
              </>
            )}
            {partner.navigation_active ? (
              <InputWrap>
                <Input
                  type="text"
                  placeholder="Username"
                  autoCompleteType={false}
                  autocomplete={false}
                  autoComplete={false}
                  value={username}
                  onChange={e => setUsername(e.target.value.replace(/[^a-zA-Z0-9_]/g, ''))}
                />
                {renderError('username')}
              </InputWrap>
            ) : null}
            <InputWrap>
              <Input
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
              {renderError('first_name')}
            </InputWrap>
            <InputWrap>
              <Input type="text" placeholder="Last Name" value={lastName} onChange={e => setLastName(e.target.value)} />
              {renderError('last_name')}
            </InputWrap>
              {partner.collect_postal_code && (
                  <InputWrap>
                      <Input
                          type="text"
                          placeholder="Postal Code"
                          value={postalCode}
                          onChange={e => setPostalCode(e.target.value)}
                      />
                      {renderError('postal_code')}
                  </InputWrap>
              )}
            {partner.cerbo_provider_id || partner.collect_dob && (
<>
    <FormMessage>
                    {t(
                        "Date of Birth"
                    )}
                </FormMessage>
              <InputWrap>
                <Input
                  type="date"
                  label="Date of Birth"
                  placeholder="Date of Birth"
                  value={dob}
                  min={HUNDRED_YEARS_AGO}
                  max={THREE_YEARS_AGO}
                  onChange={e => setDob(e.target.value)}
                />
                {renderError('dob')}
              </InputWrap>
</>
            )}
            <AuthButton
              onClick={onSignUpButtonClick}
              loading={auth.signingUp}
              disabled={
                auth.signingUp ||
                (phone && phone.length != 10) ||
                (email && password.length < 6) ||
                firstName.length < 1 ||
                lastName.length < 1 ||
                (email.length < 1 && phone.length < 1) ||
                (partner.collect_dob && !isOverAge(dob) && !(isChildAge(dob) && auth.parentalConsentGiven))
              }
              big
            >
              {config.signUpButtonText}
            </AuthButton>
          </>
        )}
      </>
    ),

    resetPassword: (
      <>
        <InputWrap>
          <Input type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
          {renderError('email', false)}
        </InputWrap>
        <AuthButton
          onClick={onResetPasswordClick}
          loading={auth.resettingPassword}
          disabled={auth.resettingPassword}
          big
        >
          {t('Reset Password')}
        </AuthButton>
        <AuthButton onClick={showSignIn} link>
          {t('Cancel')}
        </AuthButton>
      </>
    ),

    resetPasswordDone: (
      <>
        <FormMessage>
          {t(
            "Please check your email to reset your password. Be sure to check your spam folder if you don't immediately see an email from help@dietid.com"
          )}
        </FormMessage>
        <AuthButton onClick={showSignIn} big>
          {t('OK')}
        </AuthButton>
      </>
    ),

    updatePassword: (
      <>
        <InputWrap>
          <Input type="email" placeholder="Email" value={resetEmail} disabled />
          {renderError('email', false)}
        </InputWrap>
        <InputWrap>
          <Input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
          {renderError('password', false)}
        </InputWrap>
        <InputWrap>
          <Input
            type="password"
            placeholder="Confirm Password"
            value={passwordConfirmation}
            onChange={e => setPasswordConfirmation(e.target.value)}
          />
          {renderError('password_confirmation', false)}
        </InputWrap>
        {renderError('reset_token', false)}
        <AuthButton
          onClick={onUpdatePasswordClick}
          loading={auth.updatingPassword}
          disabled={auth.updatingPassword}
          big
        >
          {t('Update Password')}
        </AuthButton>
        <AuthButton onClick={clearResetToken} link>
          {t('Go to log in')}
        </AuthButton>
      </>
    ),

    updatePasswordDone: (
      <>
        <FormMessage>{t('Your password has been updated.')}</FormMessage>
        <AuthButton onClick={showSignIn} big>
          {t('Sign In')}
        </AuthButton>
      </>
    )
  }

  const SignInCTA = () =>
    nbty ? (
      <>
        <AuthButton onClick={showSignIn} link>
          {t('Already have an account? Log in.')}
        </AuthButton>
        <Attribution>{t("Nature's Bounty® is a registered trademark of The Bountiful Company.")}</Attribution>
      </>
    ) : (
      <SignInCTAWrap>
        <Heading>{t('Already have a profile?')}</Heading>
        <FormWrap>
          <AuthButton className="footer-button" onClick={showSignIn} big secondary>
            {t('Log In')}
          </AuthButton>
        </FormWrap>
      </SignInCTAWrap>
    )

  return (
    <Wrap {...props}>
      <div
        className="sidebar"
        style={
          nbty
            ? {
                backgroundImage: 'url(https://dqpn.imgix.net/assets/naturesbounty-images/auth-background.jpg)'
              }
            : {}
        }
      >
        <div className="text">
          <ReactMarkdown source={t(partner.welcome_message)} plugins={[breaks]} />
        </div>
      </div>
      <div className="content">
        <MainWrap className="wrap">
          <div className="text fixedWelcome">
            <ReactMarkdown source={t(partner.welcome_message)} plugins={[breaks]} />
          </div>
          <Logo src={partner.logo_medium_url} />
          {nbty && <PoweredBy>{t('Powered by Diet ID')}</PoweredBy>}
          <Heading className="top-heading">{heading}</Heading>
          <FormWrap>
            {view === 'SignIn' && useAppCodeForm && forms.appCodeSignIn}
            {view === 'SignIn' && !useAppCodeForm && useAccessCodeForm && forms.accessCodeSignIn}
            {view === 'SignIn' && !useAppCodeForm && !useAccessCodeForm && !virginPulseLogin && forms.emailSignIn}
            {view === 'SignIn' && !useAppCodeForm && !useAccessCodeForm && !epicLogin && virginPulseLogin &&  forms.virginPulseSignIn}
            {(view === 'SignIn' || view === 'SignUp') &&
              (view !== 'UpdatePassword' && view !== 'UpdatePasswordDone') &&
              !useAppCodeForm &&
              !useAccessCodeForm &&
              !virginPulseLogin &&
              epicLogin &&
              forms.epicSignIn}
            {view === 'SignUp' && epicLogin &&
              <FormMessage>{t('or')}</FormMessage>
            }
            {view === 'SignUp' && !useAccessCodeForm && forms.signUp}
            {view === 'ResetPassword' && forms.resetPassword}
            {view === 'ResetPasswordDone' && forms.resetPasswordDone}
            {view === 'UpdatePassword' && forms.updatePassword}
            {view === 'UpdatePasswordDone' && forms.updatePasswordDone}
          </FormWrap>
          {view === 'SignIn' && signupEnabled && !virginPulseLogin && (
            <>
              <Separator />
              <Heading>{t("Don't have a profile?")}</Heading>
              <FormWrap>
                <AuthButton className="footer-button" onClick={showSignUp} big secondary>
                  {t('Create Account')}
                </AuthButton>
              </FormWrap>
            </>
          )}
          {view === 'SignUp' && (
            <>

                {dob !== '' && !isOverAge(dob) && isChildAge(dob) && <>
                <br />
                    <Checkbox checked={auth.parental_consent_given} style={{ display: 'inline', marginTop: 5 }} onChange={toggleParentalConsent} />{
                    t('By checking this box, you confirm that you are the parent or legal guardian of the child listed above.')}
                </>}
              <Separator />
              <div className="legal-wrap">
                {t(
                  nbty
                    ? [
                        t('By continuing, you agree to our'),
                        '<br />',
                        '<a href="https://dietid.com/terms-of-service-diet-id-naturesbounty" target="_blank" rel="noopener noreferrer">Terms of Service</a>',
                        ' and ',
                        '<a href="https://www.naturesbounty.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>'
                      ].join('')
                    : [
                        t('By continuing, you are agreeing to our'),
                        '<br />',
                        `<a href="${t(
                          'https://dietid.com/terms-of-service'
                        )}" target="_blank" rel="noopener noreferrer">${t('Terms of Service')}</a>`
                      ].join('')
                )}
              </div>
            </>
          )}
          {view === 'SignUp' && config.showSignInCTABelow && <SignInCTA />}
          {view === 'SignUp' && !config.showSignInCTABelow && <SignInCTA />}
        </MainWrap>
      </div>
    </Wrap>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
  partner: state.general.partnerId ? state.data.partner[state.general.partnerId] : null
})

export default connect(mapStateToProps)(Authentication)
