import { apiCall } from 'app/lib/api'
import * as dataActions from 'app/actions/data'

export const WORKFLOW_START_NEW_WORKFLOW = 'WORKFLOW_START_NEW_WORKFLOW'
export const WORKFLOW_HANDLE_BACK_BUTTON = 'WORKFLOW_HANDLE_BACK_BUTTON'
export const WORKFLOW_SET_STEP_PROGRESS = 'WORKFLOW_SET_STEP_PROGRESS'

export const WORKFLOW_SET_PARTNER_WORKFLOW_ID = 'WORKFLOW_SET_PARTNER_WORKFLOW_ID'

export const WORKFLOW_RETAKE_WORKFLOW = 'WORKFLOW_RETAKE_WORKFLOW'

export const WORKFLOW_GET_NEXT_STEP = 'WORKFLOW_GET_NEXT_STEP'
export const WORKFLOW_GET_NEXT_STEP_DONE = 'WORKFLOW_GET_NEXT_STEP_DONE'
export const WORKFLOW_GET_NEXT_STEP_FAILED = 'WORKFLOW_GET_NEXT_STEP_FAILED'
export const WORKFLOW_GET_NEXT_STEP_WAS_INVALID = 'WORKFLOW_GET_NEXT_STEP_WAS_INVALID'

export const WORKFLOW_GET_CURRENT_STATE = 'WORKFLOW_GET_CURRENT_STATE'
export const WORKFLOW_GET_CURRENT_STATE_DONE = 'WORKFLOW_GET_CURRENT_STATE_DONE'
export const WORKFLOW_GET_CURRENT_STATE_FAILED = 'WORKFLOW_GET_CURRENT_STATE_FAILED'

export const WORKFLOW_SUBMIT_WORKFLOW_CHECKIN = 'WORKFLOW_SUBMIT_WORKFLOW_CHECKIN'
export const WORKFLOW_SUBMIT_WORKFLOW_CHECKIN_DONE = 'WORKFLOW_SUBMIT_WORKFLOW_CHECKIN_DONE'
export const WORKFLOW_SUBMIT_WORKFLOW_CHECKIN_FAILED = 'WORKFLOW_SUBMIT_WORKFLOW_CHECKIN_FAILED'

export const WORKFLOW_SHARE_DASHBOARD = 'WORKFLOW_SHARE_DASHBOARD'
export const WORKFLOW_SHARE_DASHBOARD_DONE = 'WORKFLOW_SHARE_DASHBOARD_DONE'
export const WORKFLOW_SHARE_DASHBOARD_FAILED = 'WORKFLOW_SHARE_DASHBOARD_FAILED'
export const WORKFLOW_SHARE_DASHBOARD_CLOSE = 'WORKFLOW_SHARE_DASHBOARD_CLOSE'

export const WORKFLOW_ONBOARDING_NEXT = 'WORKFLOW_ONBOARDING_NEXT'

export const WORKFLOW_SUBMIT_USER_ACCURACY_RATING = 'WORKFLOW_SUBMIT_USER_ACCURACY_RATING'
export const WORKFLOW_SUBMIT_USER_ACCURACY_RATING_DONE = 'WORKFLOW_SUBMIT_USER_ACCURACY_RATING_DONE'
export const WORKFLOW_SUBMIT_USER_ACCURACY_RATING_FAILED = 'WORKFLOW_SUBMIT_USER_ACCURACY_RATING_FAILED'

export const WORKFLOW_START_NAVIGATION = 'WORKFLOW_START_NAVIGATION'
export const WORKFLOW_START_NAVIGATION_DONE = 'WORKFLOW_START_NAVIGATION_DONE'
export const WORKFLOW_START_NAVIGATION_FAILED = 'WORKFLOW_START_NAVIGATION_FAILED'

export const WORKFLOW_UPDATE_CHALLENGES = 'WORKFLOW_UPDATE_CHALLENGES'
export const WORKFLOW_UPDATE_CHALLENGES_DONE = 'WORKFLOW_UPDATE_CHALLENGES_DONE'
export const WORKFLOW_UPDATE_CHALLENGES_FAILED = 'WORKFLOW_UPDATE_CHALLENGES_FAILED'

export function handleBackButton(e) {
  return {
    type: WORKFLOW_HANDLE_BACK_BUTTON
  }
}

export function setPartnerWorkflowId(partnerWorkflowId) {
  return {
    type: WORKFLOW_SET_PARTNER_WORKFLOW_ID,
    partnerWorkflowId
  }
}

export function retakeWorkflow(workflowType) {
  return async dispatch => {
    dispatch({ type: WORKFLOW_RETAKE_WORKFLOW })
    dispatch(startNewWorkflow(workflowType))
  }
}

export function startNewWorkflow(workflowType) {
  return async (dispatch, getState) => {
    dispatch(getNextStep(null, null, workflowType))
  }
}

export function setStepProgress({ completedSteps, totalSteps }) {
  return {
    type: WORKFLOW_SET_STEP_PROGRESS,
    payload: { completedSteps, totalSteps }
  }
}

/**
 * To make the UI look like it's "thinking" we want to artificially
 * delay the completion of this async action if it went to the server
 * and back faster than this time.  It smooths out the experience a bit.
 **/
const MIN_EXECUTION_TIME = 250

export function getNextStep(response = null, callback = null, workflowType = null) {
  return async (dispatch, getState) => {
    const s = getState()
    // Needed for handling back button nicely during onboarding
    if (
      !s.workflow.onboardingComplete &&
      !s.general.viewCustomizations.noonboarding &&
      !s.workflow.currentState.diet_id_id
    ) {
      return
    }

    let state = getState()
    let currStep = state.workflow.step
    dispatch({
      type: WORKFLOW_GET_NEXT_STEP,
      val: response,
      step: currStep ? currStep.step : null,
      workflowType: workflowType || state.workflow.activeWorkflowType
    })

    state = getState()
    currStep = state.workflow.step
    window.history.pushState({ stepIdx: state.workflow.stepIdx }, currStep ? currStep.title : 'New Workflow', [])

    // Make our api call, hoping to get info back for next step in flow
    const startTime = new Date()
    const redirectURI = localStorage.getItem('DIET_ID_REDIRECT')
    apiCall('workflows/next_step', {
      method: 'POST',
      data: {
        partner_workflow_id: state.workflow.partnerWorkflowId,
        workflow_id: state.workflow.savedWorkflowId,
        workflow_type: state.workflow.activeWorkflowType,
        step: currStep ? currStep.step : null,
        response: response,
        responses: currStep ? currStep.responses : null,
        redirect_uri: redirectURI ? redirectURI : undefined
      }
    }).then(
      resp => {
        const stopTime = new Date()
        const additionalWaitTime = Math.max(MIN_EXECUTION_TIME - (stopTime - startTime), 0)
        setTimeout(() => {
          if (resp.errors) {
            dispatch({
              type: WORKFLOW_GET_NEXT_STEP_WAS_INVALID,
              errors: resp.errors
            })
          } else {
            dispatch({
              type: WORKFLOW_GET_NEXT_STEP_DONE,
              step: resp
            })

            if (resp.finished) {
              // When all done, refresh dashboard with new info
              dispatch(getCurrentState())
            } else {
              // Special case, if a photo selection step comes back with a selected diet
              // and no available choices, let's just skip ahead again
              if (resp.type === 'photo_selection' && resp.selected_diet && resp.options.length === 0) {
                dispatch(getNextStep([true], callback, state.workflow.activeWorkflowType))
              } else {
                // Otherwise we call the callback (scroll to bottom probably)
                const advancedToNextStep = !currStep || currStep.step !== resp.step
                callback && callback(advancedToNextStep)
              }
            }
          }
        }, additionalWaitTime)
      },
      failure => {
        // Request failed for unknown reason, handle error here
        dispatch({
          type: WORKFLOW_GET_NEXT_STEP_FAILED,
          errors: failure?.json?.errors ? failure?.json?.errors : {}
        })
      }
    )
  }
}

export function getCurrentState(clearParams = false) {
  return async (dispatch, getState) => {
    dispatch({
      type: WORKFLOW_GET_CURRENT_STATE
    })
    if (clearParams) {
      window.history.replaceState(null, "Diet ID", "/")
    }
    apiCall('workflows/current_state', {
      method: 'GET',
      data: {
        cb: new Date().getTime()
      }
    }).then(
      resp => {
        const diets = {}
        if (resp.diet_id) {
          diets[resp.diet_id.id] = resp.diet_id
        }
        if (resp.diet_ideal) {
          diets[resp.diet_ideal.id] = resp.diet_ideal
        }
        dispatch(dataActions.setData('diet', diets))
        dispatch({
          state: resp,
          type: WORKFLOW_GET_CURRENT_STATE_DONE
        })

        // force a new workflow if one already exists but the partner passed a partnerWorkflowId
        if (getState().workflow.partnerWorkflowId && (resp.diet_id || resp.diet_ideal)) {
          dispatch(startNewWorkflow())
        }
      },
      failure => {
        // Request failed for unknown reason, handle error here
        dispatch({
          type: WORKFLOW_GET_CURRENT_STATE_FAILED
        })
      }
    )
  }
}

export function submitWorkflowCheckin(response) {
  return async (dispatch, getState) => {
    dispatch({
      type: WORKFLOW_SUBMIT_WORKFLOW_CHECKIN
    })
    apiCall('workflow_checkins', {
      method: 'POST',
      data: {
        workflow_checkin: {
          response
        }
      }
    }).then(
      resp => {
        dispatch(
          dataActions.setData('workflow_checkin', {
            [resp.workflow_checkin.id]: resp.workflow_checkin
          })
        )
        dispatch(
          dataActions.setData('challenge_tip', {
            [resp.challenge_tip.id]: resp.challenge_tip
          })
        )
        dispatch({
          type: WORKFLOW_SUBMIT_WORKFLOW_CHECKIN_DONE,
          ...resp
        })
      },
      failure => {
        // Request failed for unknown reason, handle error here
        dispatch({
          type: WORKFLOW_SUBMIT_WORKFLOW_CHECKIN_FAILED
        })
      }
    )
  }
}

export function shareDashboardClose() {
  return {
    type: WORKFLOW_SHARE_DASHBOARD_CLOSE
  }
}

export function shareDashboard() {
  return async (dispatch, getState) => {
    dispatch({
      type: WORKFLOW_SHARE_DASHBOARD
    })
    apiCall('workflow_shares', {
      method: 'POST',
      data: {}
    }).then(
      resp => {
        dispatch({
          type: WORKFLOW_SHARE_DASHBOARD_DONE,
          token: resp.workflow_share.token
        })
      },
      failure => {
        // Request failed for unknown reason, handle error here
        dispatch({
          type: WORKFLOW_SHARE_DASHBOARD_FAILED
        })
      }
    )
  }
}

export function onboardingNext() {
  return async (dispatch, getState) => {
    dispatch({
      type: WORKFLOW_ONBOARDING_NEXT
    })

    const { workflow, general } = getState()

    if (workflow.onboardingComplete || general.viewCustomizations.noonboarding) {
      dispatch(getNextStep())
    }
  }
}

export function submitUserAccuracyRating(ratingType, pct, info) {
  return async (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
      dispatch({
        type: WORKFLOW_SUBMIT_USER_ACCURACY_RATING
      })

      let state = getState()
      apiCall('workflows/record_user_accuracy_rating', {
        method: 'POST',
        data: {
          workflow_id: state.workflow.savedWorkflowId,
          rating_type: ratingType,
          percent: pct,
          info
        }
      }).then(
        resp => {
          dispatch({
            state: resp,
            type: WORKFLOW_SUBMIT_USER_ACCURACY_RATING_DONE
          })
          resolve()
        },
        failure => {
          // Request failed for unknown reason, handle error here
          dispatch({
            type: WORKFLOW_SUBMIT_USER_ACCURACY_RATING_FAILED
          })
          reject()
        }
      )
    })
  }
}

export function updateChallenges(challenges) {
  return async (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
      dispatch({
        type: WORKFLOW_UPDATE_CHALLENGES
      })

      let state = getState()
      apiCall('workflows/update_challenges', {
        method: 'POST',
        data: { challenges }
      }).then(
        resp => {
          dispatch({
            state: resp,
            type: WORKFLOW_UPDATE_CHALLENGES_DONE
          })
          resolve()
        },
        failure => {
          // Request failed for unknown reason, handle error here
          dispatch({
            type: WORKFLOW_UPDATE_CHALLENGES_FAILED
          })
          reject()
        }
      )
    })
  }
}

export function startNavigation({ challenges, username, email, phone }) {
  return async (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
      dispatch({
        type: WORKFLOW_START_NAVIGATION
      })

      let state = getState()
      apiCall('workflows/start_navigation', {
        method: 'POST',
        data: { challenges, username, email, phone }
      }).then(
        resp => {
          dispatch({
            state: resp,
            type: WORKFLOW_START_NAVIGATION_DONE
          })

          resp.success ? resolve() : reject(resp)
        },
        failure => {
          // Request failed for unknown reason, handle error here
          dispatch({
            type: WORKFLOW_START_NAVIGATION_FAILED
          })
          reject()
        }
      )
    })
  }
}
