import React from 'react'
import { getUrlParam } from './util'
import esLocale from '../locales/es.json'
import koLocale from '../locales/ko.json'
import viLocale from '../locales/vi.json'

const tokenRe = /\{([^\}]+)\}/gi

const translateToTacos = string => {
  const tokens = []

  string = string.replace(tokenRe, (_, token) => {
    tokens.push(token)
    return '{}'
  })

  string = string.replace(/[^\s\{\}]/g, '🌮')

  string = string.replace(/\{\}/g, () => `{${tokens.shift()}}`)

  return string
}

const locales = [
  { name: '🌮', code: '_', translate: translateToTacos },
  { name: 'English', code: 'en', translations: {} },
  { name: 'Spanish', code: 'es', translations: esLocale },
  { name: 'Korean', code: 'ko', translations: koLocale },
  { name: 'Tiếng Việt', code: 'vi', translations: viLocale }
]

let activeLocale = 'en'
let sourceStrings = []
let translatedStrings = []
let untranslatedStrings = []
let undocumentedStrings = []

let localeChangeListeners = []
let statsChangeListeners = []

export const getLocales = () => locales

export const getLocale = () => activeLocale

export const setLocale = locale => {
  let localeParam = locale.toLowerCase()
  activeLocale = locales.find(l => l.code === localeParam)

  if (!activeLocale) {
    localeParam = localeParam.split('-')[0]
    activeLocale = locales.find(l => l === localeParam)
  }

  if (!activeLocale) {
    throw new Error(`Unknown locale: ${locale}`)
  }

  resetStats()
  fireStatsChange()
  fireLocaleChange()

  return activeLocale
}

export const onLocaleChange = callback => {
  localeChangeListeners.push(callback)

  return () => {
    const index = localeChangeListeners.indexOf(callback)
    localeChangeListeners = [...localeChangeListeners.slice(0, index), ...localeChangeListeners.slice(index + 1)]
  }
}

export const fireLocaleChange = () => {
  localeChangeListeners.forEach(callback => {
    callback(activeLocale)
  })
}

export const onStatsChange = callback => {
  statsChangeListeners.push(callback)

  return () => {
    const index = statsChangeListeners.indexOf(callback)
    statsChangeListeners = [...statsChangeListeners.slice(0, index), ...statsChangeListeners.slice(index + 1)]
  }
}

export const fireStatsChange = () => {
  statsChangeListeners.forEach(callback => {
    callback({ translatedStrings, untranslatedStrings, undocumentedStrings, translations: activeLocale.translations })
  })
}

const getLocaleTranslation = normalizedString =>
  activeLocale.translate ? activeLocale.translate(normalizedString) : activeLocale.translations[normalizedString]

const resetStats = () => {
  translatedStrings = []
  untranslatedStrings = []
  undocumentedStrings = []

  sourceStrings.forEach(string => {
    updateStats(string, getLocaleTranslation(string), true)
  })
}

const updateStats = (source, translation, silent) => {
  let strings

  // if the translation file is unaware of the string
  if (typeof translation === 'undefined') {
    strings = undocumentedStrings
  }

  // if the translation file is aware of the string but doesn't have a translation
  else if (translation.length === 0) {
    strings = untranslatedStrings
  }

  // if the translation file has a translation
  else if (translation.length > 0) {
    strings = translatedStrings
  }

  if (!sourceStrings.includes(source)) {
    sourceStrings.push(source)
  }

  if (!strings.includes(source)) {
    strings.push(source)

    if (!silent) {
      fireStatsChange()
    }
  }
}

setLocale((getUrlParam('locale') || 'en').toLowerCase())

// @todo handle nested tags (or take a better approach)
const tagRe = /\<(\w+)\s?([^\>]*)\>([^\<]*)\<\/[^\>]+\>/g
const selfClosingTagRe = /\<(\w+)\s?([^\>]*)\/\>/g
const numberRe = /\d+/g

const convertAttributeStringToProps = attributes =>
  attributes.split(' ').reduce((props, attr) => {
    const [name, value] = attr.split('=')
    return { ...props, [name]: typeof value === 'undefined' ? true : value.slice(0, value.length - 1).slice(1) }
  }, {})

const parseSelfClosingTags = string => {
  let parsed = []
  let matchEnd = 0
  let hasTags = false
  let match

  while ((match = selfClosingTagRe.exec(string))) {
    hasTags = true
    const [substring, tagName, attributes] = match
    const props = attributes === '' ? null : convertAttributeStringToProps(attributes)

    parsed.push(string.slice(matchEnd, match.index), React.createElement(tagName, props))
    matchEnd = match.index + substring.length
  }

  return hasTags ? <>{[...parsed, string.slice(matchEnd)]}</> : string
}

const parseTags = string => {
  let parsed = []
  let matchEnd = 0
  let hasTags = false
  let match

  while ((match = tagRe.exec(string))) {
    hasTags = true
    const [substring, tagName, attributes, content] = match
    const props = attributes === '' ? null : convertAttributeStringToProps(attributes)

    const parsedBefore = parseSelfClosingTags(string.slice(matchEnd, match.index))
    const parsedContent = parseSelfClosingTags(content)

    parsed.push(parsedBefore, React.createElement(tagName, props, parsedContent))
    matchEnd = match.index + substring.length
  }

  return hasTags ? <>{[...parsed, parseSelfClosingTags(string.slice(matchEnd))]}</> : string
}

const normalizeNumbers = string => {
  const numbers = []

  const normalized = string.replace(numberRe, number => {
    numbers.push(number)
    return +number > 3 ? '3' : number
  })

  const denormalize = translatedString => {
    let index = 0

    return translatedString.replace(numberRe, () => {
      const realNumber = numbers[index]
      index++
      return realNumber
    })
  }

  return {
    normalized,
    denormalize
  }
}

const checkNormalization = string => {
  const { normalized, denormalize } = normalizeNumbers(string)
  const denormalized = denormalize(normalized)

  if (denormalized !== string) {
    console.error({
      message: 'String is different after normalization process',
      original: string,
      normalized,
      denormalized
    })
    throw new Error('String is different after normalization process')
  }
}

const replaceTokens = (string, tokenValues) => {
  if (!tokenValues) {
    return string
  }

  return string.replace(tokenRe, (_, token) => {
    if (!(token in tokenValues)) {
      console.error({
        message: 'String token not found in tokenValues',
        string,
        token,
        tokenValueKeys: Object.keys(tokenValues)
      })
      throw new Error('String token not found in tokenValues')
    }

    return tokenValues[token]
  })
}

const t = (string, tokenValues) => {
  if (string === '' || string === null || typeof string === 'boolean' || typeof string === 'undefined') {
    return string
  }

  if (typeof string !== 'string') {
    console.error({ message: 'Attempted to translate non-string', string })
    throw new Error('Attempted to translate non-string')
  }

  // if the locale is the original source text locale check the normalization process
  if (activeLocale.code === 'en') {
    checkNormalization(string)
  }

  const { normalized, denormalize } = normalizeNumbers(string)
  const translated = getLocaleTranslation(normalized)

  updateStats(normalized, translated)

  return parseTags(replaceTokens((translated || '').length > 0 ? denormalize(translated) : string, tokenValues))
}

export default t
