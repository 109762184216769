import React, { useEffect, useMemo } from "react";
import { connect, useSelector } from "react-redux"
import { push } from "connected-react-router"
import styled from "styled-components"

import { signOut } from "app/actions/auth"
import colors from "app/lib/colors"
import * as numbers from "app/lib/numbers"
import t from "app/lib/i18n"
import * as constants from "app/lib/constants"
import { sendDisplay, sendWorkflowComplete } from "app/lib/integration"
import { GrayHeader } from "app/components/Core"
import DietTierBadge from "app/components/DietTierBadge"
import DietCards from "app/components/DietCards"
import DietDetails from "app/components/DietDetails"
import FoodGroupInfo from "./components/FoodGroupInfo"
import UserAccuracyRater from "./components/UserAccuracyRater"

import NaturesBountyProducts from "app/custom/naturesbounty/NaturesBountyProducts"

import NextButton from "./NextButton"
import ShareSheet from "./components/ShareSheet"

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  margin: 0 auto;

  .disclaimer {
    font-size: 12px;
    color: ${colors.gray};
    margin: 40px 20px;
    text-align: center;
  }
`

const BadgeWrap = styled.div`
  margin-top: 12px;
  margin-bottom: 20px;
  text-align: center;

  .desc {
    font-size: 11px;
    font-weight: bold;
    color: ${colors.gray};
    margin-top: 30px;
  }
`

const Highlights = styled.table`
  box-sizing: border-box;
  width: calc(100% - 40px);
  margin: 20px 20px;
  border-collapse: collapse;

  .highlight td {
    border-bottom: 1px ${colors.gray} solid;
    padding: 8px;
    margin: 0;
    cellspacing: collapse;
  }

  .label {
    font-size: 10px;
    font-weight: bold;
    color: ${colors.gray};
  }

  .value {
    font-size: 10px;
    font-weight: bold;
    color: ${colors.darkGray};

    &.highlight {
      color: ${props => props.highlightColor};
    }
  }
`

const Instructions = styled.div`
  margin-bottom: 8px;
  font-size: 1.2em;
  line-height: 1.3em;
  max-width: 60%;
  text-align: center;

  .red {
    text-transform: uppercase;
    color: ${colors.red};
  }
`

const DietResults = props => {
  const {
    partner,
    step,
    onProgressChange,
    scrollRef,
    highlightColor,
    push,
    signOut,
    onSubmit,
    workflow,
    skipResults,
  } = props

  const { diet, nutrition_highlights: nutritionHighlights, disclaimer, responses } = step
  const nofeedback = useSelector(state => state.general.viewCustomizations.nofeedback)
  const nofoodgroups = useSelector(state => state.general.viewCustomizations.nofoodgroups)
  const nocalories = useSelector(state => state.general.viewCustomizations.nocalories)
  const nodetails = useSelector(state => state.general.viewCustomizations.nodetails)
  const nbty = partner.code === "nbty"
  const showShareSheet = step.share_url
  
  const shareToken = useMemo(() => (step && step.workflowShare ? step.workflowShare : null), [step])

  const onNextButtonClick = () => {
    const signOutOnFinish = !!partner.sign_out_on_finish
    const showCompletionScreen = !!partner.show_completion_screen
    const isLastStep = workflow.step.is_last_step
    const nextUrl = partner.external_link_last_step_finish_button
    
    if (isLastStep) {
      sendWorkflowComplete(partner.workflow_webhook_url_hash)
    }
    //console.log(isLastStep, signOutOnFinish)
    if (isLastStep && signOutOnFinish) {
      console.log('logging out...')
      signOut()
    }
    
    nextUrl && partner.disable_ideal ? window.open(nextUrl) : onSubmit(true)

    if (isLastStep && showCompletionScreen) {
      push("/complete")
    }

  }

  useEffect(() => {
    sendDisplay(step.step === "id_results" ? "workflowidresults" : "workflowidealresults")
    onProgressChange(step.step === "id_results" ? "IDResults" : "IdealResults", 1)
  }, [nbty])

  useEffect(() => {
    if (skipResults) {
      onNextButtonClick()
    }
  }, [skipResults])

  if (skipResults) {
    return <Wrap highlightColor={highlightColor} />
  }

  const translateList = string =>
    typeof string === "string"
      ? string
          .split(", ")
          .map(v => t(v))
          .join(", ")
      : string

  return (
    <Wrap highlightColor={highlightColor}>
      {nbty && (
        <Instructions>
          {t("Your diet might fall short of some nutrients.")}
          <br />
          {t('The <span className="red">red</span> indicators highlight those you might need to supplement.')}
        </Instructions>
      )}
      {(partner.code.includes("integration") || partner.code.includes("tangelo")) && (
        <Instructions>
          {t(
            "By learning about the quality of your healthier diet choices over time through the app, we will be improving your community's health and wellbeing together!"
          )}
          <br />
        </Instructions>
      )}
      <BadgeWrap>
        <DietTierBadge
          partner={partner} color={highlightColor} diet={diet} dietRestrictions={responses.diet_restriction_choices} />
      </BadgeWrap>
      {!nbty && !nofoodgroups && (
        <FoodGroupInfo
          highlightColor={highlightColor}
          includesTitle={diet && diet.includes_title}
          limits={diet.limits}
          food_groups_included={translateList(diet.includes)}
          lacksTitle={diet.lacks_title}
          food_groups_lacking={translateList(diet.lacks)}
          diet_restrictions={responses.diet_restriction_choices}
        />
      )}
      {!nbty &&
        !nofeedback &&
        ((step.step === "id_results" && partner.ask_for_user_accuracy_rating_for_id) ||
          (step.step === "ideal_results" && partner.ask_for_user_accuracy_rating_for_ideal)) && (
          <UserAccuracyRater ratingType={step.step === "id_results" ? "id" : "ideal"} />
        )}
      {false && (nutritionHighlights || []).length > 0 && (
        <Highlights highlightColor={highlightColor}>
          <tbody>
            {nutritionHighlights.map(h => (
              <tr className="highlight" key={h.label}>
                <td className="label">{t(h.label)}</td>
                <td className={`value ${h.highlight ? "highlight" : ""}`}>{numbers.format(h.value)}</td>
              </tr>
            ))}
          </tbody>
        </Highlights>
      )}
      {!nbty && !nocalories && <GrayHeader>{t("Your estimated Nutrient Profile")}</GrayHeader>}
      {!nocalories && (
        <DietCards
          idNutrition={step.step === "id_results" ? step.nutrients : null}
          idealNutrition={step.step === "ideal_results" ? step.nutrients : null}
          gender={responses.user_info.gender}
        />
      )}
      {!nodetails && (
        <DietDetails
          partner={partner}
          idNutrition={step.step === "id_results" ? step.nutrients : null}
          idealNutrition={step.step === "ideal_results" ? step.nutrients : null}
          healthGoals={(step.responses.ideal_goals || {}).for_health}
          scrollRef={scrollRef}
          shareToken={shareToken}
          workflowID={workflow.step.workflow_id}
        />
      )}
      {nbty && step.step === "id_results" && (
        <NaturesBountyProducts
          idDietCode={diet.code}
          idNutrition={step.nutrients}
          userInfoCustomField={responses.user_info.custom_field}
        />
      )}
      {nbty && (
        <div className="disclaimer">
          {t(
            "Health is personal. If you are pregnant, nursing, taking any medications, have been diagnosed with any medical condition, or are planning any medical or surgical procedure, please consult your doctor before taking any new supplements."
          )}
        </div>
      )}
      {showShareSheet && <ShareSheet shareUrl={step.share_url} onClose={onNextButtonClick} partner={partner} />}
      {!nbty && disclaimer && <div className="disclaimer">*{t(disclaimer)}</div>}
      {!nbty && <NextButton {...props} onClick={onNextButtonClick} />}
    </Wrap>
  )
}

const mapStateToProps = state => ({
  skipResults: state.general.viewCustomizations.noworkflowresults,
  idNutritionInfo: state.workflow.currentState.diet_id_nutrition_info,
})

export default connect(mapStateToProps, { push, signOut })(DietResults)
