import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { getDietRestrictionsForScreener } from './logic'
import ListSelection from './components/ListSelection'
import NextButton from './NextButton'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 13px;
`

const QuestionMultipleSelect = props => {
  const { step, onProgressChange, onSubmit } = props
  const [values, setValues] = useState(
    step.step === 'diet_restriction_choices' ? getDietRestrictionsForScreener(step.responses.diet_screener) : []
  )

  useEffect(() => {
    onProgressChange('DietRestrictionChoices', 0)
  }, [])

  const onNextButtonClick = () => {
    onProgressChange('DietRestrictionChoices', 1)
    onSubmit(values)
    setValues([])
  }

  return (
    <Wrap>
      <ListSelection
        options={step.options}
        selections={values}
        onChange={selections => setValues(selections)}
        animate
      />
      <br/>
      <NextButton {...props} onClick={onNextButtonClick} disabled={!values.length && step.step !== 'diet_restriction_choices'} />
    </Wrap>
  )
}

export default QuestionMultipleSelect
