import React, { Component } from 'react'
import styled from 'styled-components'
import NextButton from './NextButton'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export default class CustomConsent extends Component {
  constructor(props) {
    super(props)
  }
  handleNext = () => {
    const submission = {
      key: 'consent',
      label: 'Yes'
    }
    this.props.onSubmit(submission)
  }
  render() {
    return (
      <Wrap>
        <NextButton {...this.props} onClick={this.handleNext} label="Next" />
      </Wrap>
    )
  }
}
