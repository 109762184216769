import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as constants from 'app/lib/constants'
import colors from 'app/lib/colors'
import t from 'app/lib/i18n'

const activeCircleSize = 22
const circleSize = 18
const innerCircleSize = 8
const lineHeight = 2
const lineOffset = Math.floor(activeCircleSize / 2)

const Wrap = styled.div`
  text-align: center;
  margin-bottom: 20px;

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .progress-steps {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }

  .progress-step {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    align-items: center;

    .progress-circle {
      position: relative;
      z-index: 2;
      height: ${innerCircleSize}px;
      width: ${innerCircleSize}px;
      border-radius: ${circleSize}px;
      background-color: ${props => colors.hexToRgb(props.highlightColor, 0.16)};
      border: 1px ${props => props.highlightColor} solid;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translate(0, -${(activeCircleSize - innerCircleSize) / 2}px);
    }

    &.complete .progress-circle {
      height: ${circleSize}px;
      width: ${circleSize}px;
      border-radius: ${circleSize}px;
      background-color: ${props => props.highlightColor};
      transform: translate(0, -${(activeCircleSize - circleSize) / 2}px);

      :before {
        content: ' ';
        height: ${innerCircleSize}px;
        width: ${innerCircleSize}px;
        border-radius: ${innerCircleSize}px;
        background-color: ${colors.white};
      }
    }

    :before {
      content: ' ';
      z-index: 0;
      position: absolute;
      bottom: ${lineOffset}px;
      left: 0;
      right: 0;
      height: ${lineHeight}px;
      width: calc(50% - ${innerCircleSize / 2}px);
      background-color: ${props => colors.hexToRgb(props.highlightColor, 0.36)};
    }

    :after {
      content: ' ';
      z-index: 0;
      position: absolute;
      bottom: ${lineOffset}px;
      left: calc(50% + ${innerCircleSize / 2}px);
      right: 0;
      height: ${lineHeight}px;
      width: calc(50% - ${innerCircleSize / 2}px);
      background-color: ${props => colors.hexToRgb(props.highlightColor, 0.36)};
    }

    &:first-of-type {
      :before {
        width: 0%;
      }
    }

    &:first-of-type.next {
      :before {
        width: 0%;
      }
    }

    &:last-of-type {
      :after {
        width: 0%;
      }
    }
    &:last-of-type.next {
      :after {
        width: 0%;
      }
    }

    &.complete {
      :after {
        background-color: ${props => props.highlightColor};
      }
      :before {
        background-color: ${props => props.highlightColor};
      }
    }

    /* The "next" one should have color before and gray after 50/50 */
    &.next {
      .progress-active-line {
        visibility: visible;
        height: 13px;
        width: 1px;
        background-color: ${props => props.highlightColor};
      }

      .progress-circle {
        transform: translate(0, 0px);
        width: ${activeCircleSize}px;
        height: ${activeCircleSize}px;
        border-radius: ${activeCircleSize}px;
        border: 1px ${props => props.highlightColor} solid;
        background-color: ${props => colors.hexToRgb(props.highlightColor, 0.36)};

        :before {
          content: ' ';
          background-color: ${props => props.highlightColor};
          width: ${innerCircleSize}px;
          height: ${innerCircleSize}px;
          border-radius: ${circleSize}px;
          margin-left: 0px;
        }
      }

      :before {
        content: ' ';
        z-index: 0;
        position: absolute;
        bottom: ${Math.floor(activeCircleSize / 2 + lineHeight / 2)}px;
        bottom: ${lineOffset}px;
        left: 0%;
        height: ${lineHeight}px;
        width: calc(50% - ${activeCircleSize / 2}px);
        background-color: ${props => props.highlightColor};
      }

      :after {
        content: ' ';
        z-index: 0;
        position: absolute;
        bottom: ${lineOffset}px;
        left: calc(50% + ${activeCircleSize / 2}px);
        right: 0;
        height: ${lineHeight}px;
        width: calc(50% - ${activeCircleSize / 2}px);
        background-color: ${props => colors.hexToRgb(props.highlightColor, 0.36)};
      }
    }
  }

  .progress-active-line {
    display: none;
    visibility: hidden;
  }

  .progress-heading {
    display: none;
    color: ${props => props.highlightColor};
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin: 5px 12px 15px 12px;
  }

  .powered-by {
    font-size: 12px;
    font-weight: bold;
    margin-top: 20px;

    display: ${props => (props.neverShowLogo ? 'none!important' : 'block')};
    @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
      display: ${props => (props.alwaysShowLogo ? 'block' : 'none')};
    }
  }
`

const Logo = styled.img`
  min-height: 42px;
  max-height: 300px;
  max-width: 300px;
  margin: 10px auto 40px;

  display: ${props => (props.neverShowLogo ? 'none!important' : 'block')};
  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    display: ${props => (props.alwaysShowLogo ? 'block' : 'none')};
  }
`

class Progress extends Component {
  renderStep(step, isNext) {
    return (
      <div className={`progress-step ${step.complete ? 'complete' : ''} ${isNext ? 'next' : ''}`} key={step.step}>
        <div className="progress-active-line" />
        <div className={`progress-circle`} />
      </div>
    )
  }

  render() {
    const { step } = this.props.workflow
    const progress = step ? step.progress : null
    if (!progress) {
      return <span />
    }

    let completePercent = 0
    progress.forEach((step, idx) => {
      if (step.complete) {
        completePercent = (idx + 1) / progress.length
      }
    })

    return (
      <Wrap {...this.props} completePercent={completePercent}>
        <Logo
          alwaysShowLogo={!!this.props.alwaysShowLogo}
          neverShowLogo={!!this.props.neverShowLogo}
          src={this.props.partner.logo_small_url}
        />
        {this.props.partner.show_powered_by_diet_id ? (
          <div className="powered-by">{t('Powered by Diet ID')}</div>
        ) : null}
        <div className="progress-heading">{step.title}</div>
        <div className="progress-steps">
          {progress.map((step, idx) =>
            this.renderStep(step, !step.complete && (idx === 0 || progress[idx - 1].complete))
          )}
        </div>
      </Wrap>
    )
  }
}

export default connect((state, ownProps) => {
  return {
    partner: ownProps.partner || (state.general.partnerId ? state.data.partner[state.general.partnerId] : null),
    workflow: ownProps.workflow || state.workflow
  }
})(Progress)
